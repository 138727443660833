import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import magnifier from '../../../../../assests/icons/magnifier.svg';
import { updateCurrentTab } from '../../../../../reducers/appDetails';
import { RECORD_PAGE_TABS } from '../../../../../constants/record';

function UserCancelled() {
  const dispatch = useDispatch();
  const recordData = useSelector((state) => state.appDetails.recordData);
  const reasonBehindTheStatusModules = useSelector(
    (state) => state.appDetails.reasonBehindTheStatusModules,
  ) || [];

  const handleViewInTimeline = () => {
    dispatch(updateCurrentTab(RECORD_PAGE_TABS.TIMELINE));
  };

  const getModuleName = () => {
    if (reasonBehindTheStatusModules.length > 0) {
      return get(
        recordData,
        `${reasonBehindTheStatusModules[0]}.moduleName`,
        'some step',
      );
    }
    return 'some step';
  };

  return (
    <div id="reason_behind_status_container__user_cancelled">
      <div id="reason_behind_status_container__user_cancelled__icon_section">
        <div>
          <img src={magnifier} alt="img" />
        </div>
      </div>
      <div id="reason_behind_status_container__user_cancelled__content_section">
        <div>
          <span>
            User Cancelled at
            {' '}
            {getModuleName()}
          </span>
          <p>No information recorded for this step</p>
        </div>
        <p onClick={handleViewInTimeline} aria-hidden>
          View in Timeline
        </p>
      </div>
    </div>
  );
}

export default UserCancelled;
