import React from 'react';
import PropTypes from 'prop-types';
import { ToolTip } from 'clm-components';
import { useSelector, useDispatch } from 'react-redux';
import getIconForSubType from './utils';
import { updateIsReasonBehindStatusCollapsibleOpened } from '../../../reducers/appDetails';

function LeftPanelItem({
  moduleName, moduleId, subType, isSuccess, onModuleClick, selectedModule, key,
}) {
  const dispatch = useDispatch();
  const failureReason = useSelector((state) => state.appDetails.failureReason);
  const getActiveClassName = (currentModuleId) => (selectedModule === currentModuleId ? 'active' : '');

  const handleBodyPanelItemsClick = (updatedModuleId) => {
    if (updatedModuleId) {
      dispatch(updateIsReasonBehindStatusCollapsibleOpened(true));
      onModuleClick(updatedModuleId);
    }
  };

  return (
    <ToolTip title={moduleName} key={key} placement="right">
      <div
        key={key}
        id="leftPanel__body__item"
        className={getActiveClassName(moduleId)}
        onClick={() => handleBodyPanelItemsClick(moduleId)}
        aria-hidden
      >
        {React.cloneElement(getIconForSubType(subType))}
        <span>{moduleName}</span>
        {!isSuccess && !failureReason && (
        <div id="leftPanel__body__item__status__fail" />
        )}
      </div>
    </ToolTip>
  );
}

LeftPanelItem.propTypes = {
  moduleName: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  onModuleClick: PropTypes.func.isRequired,
  selectedModule: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
};

export default LeftPanelItem;
