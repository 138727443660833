import React from 'react';
import PropTypes from 'prop-types';
import './Details.scss';
import getIconForSubType from '../LeftPanel/utils';
import { ReactComponent as VectorIcon } from '../../../assests/icons/vector.svg';

function DetailsCollapsible({
  isOpen,
  children,
  subType,
  text,
  labelIcon,
  name,
  handleToggleCollapsible,
}) {
  return (
    <>
      <div
        aria-hidden
        id="application_details_collapsible"
        className={`${
          isOpen ? 'border_radius_default' : 'border_radius_enabled'
        }`}
        onClick={handleToggleCollapsible}
      >
        <div id="application_details_collapsible__left_column">
          {React.cloneElement(getIconForSubType(subType))}
          <div id="application_details_collapsible__text">{text}</div>
          {labelIcon && (
            <div id="application_details_collapsible__label">
              {React.cloneElement(getIconForSubType(labelIcon))}
            </div>
          )}
        </div>
        <div
          id="application_details_collapsible__right_column"
          className={isOpen ? 'active' : ''}
        >
          <VectorIcon />
        </div>
      </div>
      {isOpen && (
        <div id="application_details_collapsible__children" name={name}>
          {children}
        </div>
      )}
    </>
  );
}

DetailsCollapsible.defaultProps = {
  subType: 'application_details_icon',
  text: 'Application Details',
  isOpen: true,
  labelIcon: '',
  name: '',
  handleToggleCollapsible: () => {},
};

DetailsCollapsible.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  subType: PropTypes.string,
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  labelIcon: PropTypes.string,
  name: PropTypes.string,
  handleToggleCollapsible: PropTypes.func,
};

export default DetailsCollapsible;
