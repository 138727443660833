import React from 'react';

function EmptyState() {
  return (
    <div id="reason_behind_status_container__user_cancelled">
      <p>No reason found !</p>
    </div>
  );
}

export default EmptyState;
