import React, { useState, useEffect } from 'react';
import { first, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Spinner } from 'clm-components';
import './Card.scss';
import * as Sentry from '@sentry/react';
import '../../../Details.scss';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import CustomTabs from '../../../../Common/Utils/CustomTabs';
import ContentError from './Content/ContentError';
import ContentWrapper from './Content/ContentWrapper';
import { updateAvailableData } from '../../../../../reducers/appDetails';
import { getFilesFromRecord } from './Content/utils';
import { MissingRecordComponent } from '../Common';
import FetchDataError from './FetchDataError';
import errorCode from '../../../../../constants/errorCode';
import fetchDataForCard from '../../../../../api/record';
import { recordsToRender } from '../../Details/utils';

function Card({
  moduleId,
  moduleName,
  subType,
  transactionData,
  records,
  hideHeader,
  hideTabs,
  hideCreatedAtAndStatus,
}) {
  const [currentRecord, setCurrentRecord] = useState({});
  const [currentRecordIndex, setCurrentRecordIndex] = useState(0);
  const [moduleConfig, setModuleConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const appId = useSelector((state) => get(state, 'user.credState.current.appId', ''));

  const dispatch = useDispatch();
  const currentTabToView = useSelector((state) => state.appDetails.currentTab) || '';

  useEffect(() => {
    if (!isEmpty(records)) {
      const filteredRecords = recordsToRender(records, currentTabToView);

      setCurrentRecord(first(filteredRecords));
    }
  }, [records]);

  const isMissing = currentRecord?.isMissing || false;

  const fetchCurrentRecordData = async () => {
    if (!currentRecord.isMissing) {
      let updatedCurrentData = currentRecord;
      const fileData = getFilesFromRecord(currentRecord);

      const isFetchData = get(currentRecord, 'fetchData', false);

      if (isFetchData) {
        const requestId = get(currentRecord, 'requestId', '');
        const transactionId = get(transactionData, 'transactionId', '');
        const endpoint = get(currentRecord, 'endpointModule.cardDataAPI', '');

        const payload = {
          appId,
          transactionId,
          requestId,
          endpoint,
        };
        if (isEmpty(requestId) || isEmpty(transactionId) || isEmpty(endpoint) || isEmpty(appId)) {
          Sentry.captureException(`${errorCode.MISSING_PARAMS_ON_DYNAMIC_CARD} - requestId: ${requestId}, transactionId: ${transactionId}, endpoint: ${endpoint}, appId: ${appId}`);
          setIsLoading(false);
          setIsError(true);
          return;
        }

        let response;
        try {
          setIsError(false);
          setIsLoading(true);
          response = await fetchDataForCard(payload);
          setIsLoading(false);
        } catch (error) {
          Sentry.captureException(`${errorCode.ERROR_ON_FETCHING_DYNAMIC_CARD_DATA} - ${error}`);
          setIsLoading(false);
          setIsError(true);
          response = {};
        }

        updatedCurrentData = {
          ...updatedCurrentData,
          extractedData: response,
        };
      }

      dispatch(updateAvailableData({
        moduleId,
        data: {
          transactionData,
          recordData: updatedCurrentData,
          fileData,
        },
      }));

      setModuleConfig(get(currentRecord, 'endpointModule', {}));
    } else {
      setModuleConfig({});
    }
  };

  useEffect(() => {
    if (!isEmpty(currentRecord) && !isEmpty(appId)) {
      fetchCurrentRecordData();
    }
  }, [currentRecord, appId]);

  useEffect(() => {
    setCurrentRecord(records[currentRecordIndex]);
  }, [currentRecordIndex]);

  const onRetryToFetchDataForDynamicCard = () => {
    setIsError(false);
    fetchCurrentRecordData();
  };

  const { contentConfig = {}, hitsConfig = {} } = moduleConfig;

  return (
    <div className="card_type_five__container">
      {!hideHeader && <Header moduleName={moduleName} subType={subType} />}
      {!hideTabs && (
      <CustomTabs
        className="card_type_five__tabs"
        tabIndex={currentRecordIndex}
        setTabIndex={setCurrentRecordIndex}
        tabArray={records}
      />
      )}
      {isLoading && <div className="card_type_five__spinner_container"><Spinner /></div>}
      {isError && <FetchDataError onRetry={onRetryToFetchDataForDynamicCard} text="Error Loading content" />}
      {!isMissing
      && !isEmpty(contentConfig) && !isLoading && !isError ? (
        <ContentWrapper
          contentConfig={contentConfig}
          hitsConfig={hitsConfig}
          moduleId={moduleId}
          hideCreatedAtAndStatus={hideCreatedAtAndStatus}
        />
        ) : (
          !isMissing && !isLoading && !isError && isEmpty(contentConfig) && <ContentError />
        )}
      {isMissing && <MissingRecordComponent />}
    </div>
  );
}

Card.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

Card.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  transactionData: PropTypes.object.isRequired,
  records: PropTypes.array.isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default Card;
