import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SummaryMedia from './SummaryMedia';
import { updateUserDetailsOpenState } from '../../../reducers/summary';
import Collapsible from './SummaryCollapsible';
import MetaData from './MetaComponents/MetaData';

function SummaryBody({
  images,
  videos,
  pdfs,
  metaData,
  userDetails,
}) {
  const dispatch = useDispatch();

  const isUserDetailsOpen = useSelector((state) => state.summary.isUserDetailsOpen);
  const toggleUserDetailsOpenState = () => {
    dispatch(updateUserDetailsOpenState(!isUserDetailsOpen));
  };
  return (
    <>
      <div id="summary__body_container">
        <span>User Information</span>
        <MetaData data={metaData} />
        <Collapsible
          title="User Details"
          isExpanded={isUserDetailsOpen}
          toggleExpandedState={toggleUserDetailsOpenState}
          data={userDetails}
        />
        <SummaryMedia images={images} videos={videos} pdfs={pdfs} />
      </div>
    </>
  );
}

SummaryBody.defaultProps = {
  images: [],
  metaData: [],
  userDetails: [],
  videos: [],
  pdfs: [],
};

SummaryBody.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  pdfs: PropTypes.array,
  metaData: PropTypes.array,
  userDetails: PropTypes.array,
};

export default SummaryBody;
