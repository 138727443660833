import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ToolTip } from 'clm-components';
import {
  updateIsApplicationDetailsCollapsibleOpened,
} from '../../../reducers/appDetails';
import { workflowDetailsModuleId, userInformationModuleId } from '../RecordUtils';
import { ReactComponent as AppDetailsIcon } from '../../../assests/icons/appDetails.svg';

function LeftPanelHeader({ selectedModule, onModuleClick }) {
  const dispatch = useDispatch();
  const getActiveClassName = (moduleId) => (selectedModule === moduleId ? 'active' : '');

  const handleLeftPanelTopBarItemClick = (moduleId) => {
    if (moduleId) {
      dispatch(updateIsApplicationDetailsCollapsibleOpened(true));
      onModuleClick(moduleId);
    }
  };

  return (
    <div id="leftPanel__header">
      <h2>Application Details</h2>
      <ToolTip title="Application details" placement="right">
        <div
          id="leftPanel__header__item"
          className={getActiveClassName(workflowDetailsModuleId)}
          onClick={() => handleLeftPanelTopBarItemClick(workflowDetailsModuleId)}
          aria-hidden
        >
          <AppDetailsIcon />
          <span>Workflow details</span>
        </div>
      </ToolTip>
      <ToolTip title="User Information" placement="right">
        <div
          id="leftPanel__header__item"
          className={getActiveClassName(userInformationModuleId)}
          onClick={() => handleLeftPanelTopBarItemClick(userInformationModuleId)}
          aria-hidden
        >
          <AppDetailsIcon />
          <span>User Information</span>
        </div>
      </ToolTip>
    </div>
  );
}

LeftPanelHeader.propTypes = {
  selectedModule: PropTypes.string.isRequired,
  onModuleClick: PropTypes.func.isRequired,
};

export default LeftPanelHeader;
