import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

import '../Details.scss';
import DetailsCollapsible from './DetailsCollapsible';
import {
  updateReasonBehindTheStatusModules,
  updateIsReasonBehindStatusCollapsibleOpened,
} from '../../../reducers/appDetails';
import ReasonBehindTheStatusNotEmptyState from './utils/ReasonBehindTheStatus/ReasonBehindTheStatusNotEmptyState';
import ReasonBehindTheStatusEmptyState from './utils/ReasonBehindTheStatus/ReasonBehindTheStatusEmptyState';

function ReasonBehindStatus({ transactionData }) {
  const dispatch = useDispatch();
  const { workflowId } = transactionData;
  const currentAppId = useSelector((s) => s.user.credState?.current?.appId);
  const {
    workflowModules: workflowModuleArray,
    contributingModulesForEndState,
    recordData,
  } = useSelector((state) => ({
    workflowModules: state.user.workflowModules,
    contributingModulesForEndState:
      state.appDetails.contributingModulesForEndState,
    recordData: state.appDetails.recordData,
  }));
  const isReasonBehindStatusCollapsibleOpened = useSelector(
    (state) => state.appDetails.isReasonBehindStatusCollapsibleOpened,
  );

  const handleReasonBehindStatusCollapsibleClick = () => {
    const collapsibleState = !isReasonBehindStatusCollapsibleOpened;
    dispatch(updateIsReasonBehindStatusCollapsibleOpened(collapsibleState));
  };

  // const workflowModules = initWorkflowModules[`${currentAppId}_${workflowId}`];
  const workflowModules = get(
    workflowModuleArray,
    `${currentAppId}_${workflowId}.modules`,
    [],
  );

  const [processedRecordData, setProcessedRecordData] = useState([]);
  const getProcessedRecordData = () => {
    if (!contributingModulesForEndState || !workflowModules || !recordData) {
      return [];
    }

    const {
      contributingModuleIdsForEndState = [],
      contributingSuperModuleIdsForEndState = [],
    } = contributingModulesForEndState;

    const moduleIds = [
      ...contributingModuleIdsForEndState,
      ...contributingSuperModuleIdsForEndState,
    ].reduce((uniqueSet, moduleId) => {
      workflowModules?.forEach((module) => {
        if (module.id === moduleId || module.superModuleId === moduleId) {
          uniqueSet.add(module.id);
        }
      });
      return uniqueSet;
    }, new Set());

    const finalModuleIds = [...Array.from(moduleIds)];
    dispatch(updateReasonBehindTheStatusModules(finalModuleIds));

    const finalRecords = finalModuleIds.reduce((acc, moduleId) => {
      const record = recordData[moduleId];

      if (!record) {
        return acc;
      }

      const firstRecord = get(record, 'records.length', 0) > 1
        ? [get(record, 'records[0]')]
        : get(record, 'records', []);

      const processedRecord = {
        moduleId,
        ...record,
        records: firstRecord,
      };

      return [...acc, processedRecord];
    }, []);

    setProcessedRecordData(finalRecords);
    return finalRecords;
  };

  useEffect(() => {
    getProcessedRecordData();
  }, []);

  return (
    <div id="reason_behind_status_container">
      {processedRecordData && !isEmpty(processedRecordData) ? (
        <DetailsCollapsible
          subType="reason_behind_status_icon"
          text="Reason Behind the Status"
          labelIcon="new_badge_icon"
          name="reason_behind_status_modules"
          isOpen={isReasonBehindStatusCollapsibleOpened}
          handleToggleCollapsible={handleReasonBehindStatusCollapsibleClick}
        >
          <ReasonBehindTheStatusNotEmptyState
            processedRecordData={processedRecordData}
            transactionData={transactionData}
          />
        </DetailsCollapsible>
      ) : (
        <DetailsCollapsible
          subType="reason_behind_status_icon"
          text="Reason Behind the Status"
          labelIcon="new_badge_icon"
          isOpen={isReasonBehindStatusCollapsibleOpened}
          handleToggleCollapsible={handleReasonBehindStatusCollapsibleClick}
        >
          <ReasonBehindTheStatusEmptyState />
        </DetailsCollapsible>
      )}
    </div>
  );
}

ReasonBehindStatus.propTypes = {
  transactionData: PropTypes.object.isRequired,
};

export default ReasonBehindStatus;
