import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './Summary.scss';
import SummaryHeader from './SummaryHeader';
import SummaryBody from './SummaryBody';
import {
  getSummaryHeaderData,
  getSummaryMetaData,
  getSummaryUserDetailsData,
  collectVideos,
  collectMedia,
} from './utils';
// import { summaryModuleId } from '../RecordUtils';
import DetailsCollapsible from '../Body/DetailsCollapsible';
import { updateIsApplicationDetailsCollapsibleOpened } from '../../../reducers/appDetails';

function SummaryTab({ transactionData, recordData }) {
  const dispatch = useDispatch();
  const [summaryImages, setSummaryImages] = useState([]);
  const [summaryPDFs, setSummaryPDFs] = useState([]);
  const [summaryVideos, setSummaryVideos] = useState([]);
  const [summaryHeaderData, setSummaryHeaderData] = useState([]);
  const [summaryMetaData, setSummaryMetaData] = useState([]);
  const [summaryUserDetails, setSummaryUserDetails] = useState([]);
  const isApplicationDetailsCollapsibleOpened = useSelector(
    (state) => state.appDetails.isApplicationDetailsCollapsibleOpened,
  );

  const handleCollapsibleClick = () => {
    dispatch(updateIsApplicationDetailsCollapsibleOpened(!isApplicationDetailsCollapsibleOpened));
  };

  useEffect(() => {
    const { imagesCollected, pdfsCollected } = collectMedia(recordData);
    setSummaryImages(imagesCollected);
    setSummaryPDFs(pdfsCollected);
    setSummaryVideos(collectVideos(recordData));
  }, [recordData]);

  useEffect(() => {
    setSummaryHeaderData(getSummaryHeaderData(transactionData));
    setSummaryMetaData(getSummaryMetaData(transactionData));
    setSummaryUserDetails(getSummaryUserDetailsData(transactionData));
  }, [transactionData]);

  return (
    <div>
      <DetailsCollapsible
        subType="application_details_icon"
        text="Application Details"
        name="application_details_and_user_information"
        isOpen={isApplicationDetailsCollapsibleOpened}
        handleToggleCollapsible={handleCollapsibleClick}
      >
        <SummaryHeader data={summaryHeaderData} />
        <SummaryBody
          images={summaryImages}
          videos={summaryVideos}
          pdfs={summaryPDFs}
          metaData={summaryMetaData}
          userDetails={summaryUserDetails}
        />
      </DetailsCollapsible>
    </div>
  );
}

SummaryTab.propTypes = {
  transactionData: PropTypes.object.isRequired,
  recordData: PropTypes.array.isRequired,
};

export default SummaryTab;
