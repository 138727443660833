import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CarrotDownIcon from '../../../assests/icons/carrotDown04.svg';

function Collapsible({
  title,
  data,
  isExpanded,
  toggleExpandedState,
}) {
  if (data.length > 0) {
    return (
      <div id="summary__metadata__container">
        {!isEmpty(title) && (
          <button
            id="summary__metadata__header"
            type="button"
            className={`${isExpanded && 'active'}`}
            onClick={toggleExpandedState}
          >
            {title}
            <img src={CarrotDownIcon} alt="" />
          </button>
        )}
        {isExpanded && (
        <div id="summary__metadata__details">
          {data && data.map(({
            id, label, value, componentRenderer,
          }) => (
            <p id={id} key={id}>
              {label}
              :
              <span>
                {componentRenderer ? componentRenderer({ value }) : value}
              </span>
            </p>
          ))}
        </div>
        )}
      </div>
    );
  }
  return '';
}

Collapsible.defaultProps = {
  data: [],
  title: '',
};

Collapsible.propTypes = {
  title: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  toggleExpandedState: PropTypes.func.isRequired,
  data: PropTypes.array,
};

export default Collapsible;
