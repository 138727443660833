const errorCode = {
  USAGE_FETCH_ERROR: 'usage/fetch-error',
  USAGE_DUPLICATE_REQUEST: 'usage/duplicate-request',
  NDA_SAVE_ERROR: 'nda/save-error',
  APPLICATION_DUPLICATE_DOWNLOAD: 'application-list/duplicate-download',
  FETCH_APPLICATION_ERROR: 'application-list/fetch-application-error',
  FETCH_TABLE_DATA: 'application/fetch-table-data',
  FETCH_ROW_COUNT: 'application/fetch-row-count',
  FETCH_PRODUCTS: 'application/fetch-product',
  APPLICATION_FETCH_COUNT_ERROR: 'application-list/fetch-count-error',
  APPLICATION_DUPLICATE_TRANSACTION_ID: 'new-application/duplicate-transaction-id',
  APPLICATION_INVLAID_TRANSACTION_ID: 'new-application/invalid-transaction-id',
  APPLICATION_GENERATE_LINK_ERROR: 'new-application/generate-link-error',
  APPLICATION_NO_WORKFLOWS: 'new-application/no-workflow',
  APPLICATION_DYNAMIC_CARD_API_ERROR: 'application-detail/dynamic-card/api-error',
  APPLICATION_DYNAMIC_CARD_MENU_BUTTON_API_ERROR: 'application-detail/dynamic-card/menu-button-api-error',
  APPLICATION_STATUS_UPDATE_ERROR: 'application-detail/review-action/status-update-error',
  APPLICATION_REVIEW_DESCISIONS_CONFIG_FETCH_ERROR: 'application-detail/review-decisions/error-on-s3-fetch',
  CREDENTAIL_FETCH_ERROR: 'dev-hub/credential-fetch-error',
  HOME_INVALID_TRANSACTION_ID: 'home/demo/duplicate-transaction-id',
  HOME_GENERATE_LINK_ERROR: 'home/demo/generate-link-error',
  HOME_EMAIL_SENDING_LIMIT_EXCEEDED: 'home/demo/email-limit-exceeded',
  HOME_ERROR_IN_SEND_MAIL: 'home/demo/email-send-error',
  HOME_ERROR_FETCHING_WORKFLOWS: 'home/fetch-workflow-error',
  APPLICATION_ERROR_FETCHING_WORKFLOW: 'application-list/fetch-workflow-error',
  APPLICATION_ASSIGNMENT_CONFIG_ERROR: 'application-list/fetch-application-assignment-config-error',
  DRAWER_DOC_TOKEN_ERROR: 'drawer/doc-token-error',
  DRAWER_INSTATUS_LINK_FETCH_ERROR: 'drawer/instatus-page-link-fetch-error',
  ONBOARD_CREATE_PASSWORD_ERROR: 'onboarding/create-password-error',
  ONBOARDING_ERROR_IN_SENDING_MAIL: 'onboarding/reset-password-email-error',
  ONBOARDING_COMPANY_DISABLED: 'onboarding/signup/company-disabled',
  ONBOARDING_SIGNUP_ERROR: 'onboarding/signup/error',
  AUTH_USER_DISABLED: 'auth/user-disabled',
  USAGE_NO_LOGS: 'usage/fetch-logs/no-logs',
  USAGE_GENERATE_TRY_AGAIN_LATER: 'usage/fetch-logs/try-again',
  USAGE_INTERNAL_ERROR: 'usage/fetch-logs/internal-error',
  TRANSACTION_NO_LOGS: 'transaction/fetch-logs/no-log',
  TRANSACTION_GENERATE_TRY_AGAIN_LATER: 'transaction/fetch-logs/try-again',
  TRANSACTION_INTERNAL_ERROR: 'transaction/fetch-logs/internal-error',
  AUDIT_NO_LOGS: 'audit/fetch-logs/no-logs',
  AUDIT_SIZE_LIMIT_EXCEEDED: 'audit/fetch-logs/size-limit-exceeded',
  AUDIT_INTERNAL_ERROR: 'audit/fetch-logs/internal-error',
  USAGE_FETCH_INTERNAL_ERROR: 'usage/module-pricing/fetch-internal-error',
  APPLICATION_FETCH_CREDENTIAL_ERROR: 'application-detail/fetch-credentials-error',
  APPLICATION_FETCH_RECORD_ERROR: 'application-detail/fetch-records-error',
  APPLICATION_FETCH_COMMENT_ERROR: 'application-detail/fetch-comments-error',
  APPLICATION_UPDATE_TRANSACTION_ERROR: 'application-detail/update-transaction-error',
  APPLICATION_ADD_COMMMENT_ERROR: 'application-detail/add-comment-error',
  REDIRECT_TOKEN_ERROR: 'redirect/dock-token-error',
  INTERNAL_GOOGLE_LOGIN_ERROR: 'internal-login/google-url-error',
  AUTH_EXPIRY_TOKEN: 'auth/expired-token',
  AUTH_ERROR_FETCHING_USER: 'auth/fetch-user-error',
  APPLICATION_COUNT_ERROR: 'application/count-error',
  APPLICATION_DATA_ERROR: 'application/data-error',
  LOGIN_ERROR: 'login/error',
  UNHANDLED_ERROR: 'unhandled-error',
  APPLICATION_LOAD_PREV_ERROR: 'application-detail/load-prev-error',
  APPLICATION_LOAD_NEXT_ERROR: 'application-detail/load-next-error',
  RUDDERSTACK_ERROR: 'rudderstack/error',
  APPLICATION_FECTH_FILTER_ERROR: 'application/fetch-list-error',
  ERROR_PROCESS_RECORD: 'record/process-record-skyc',
  MISSING_PARAMS_ON_DYNAMIC_CARD: 'dynamic-card/missing-params',
  ERROR_ON_FETCHING_DYNAMIC_CARD_DATA: 'dynamic-card/fetch-data-error',
  ERROR_FETCHING_HOMEPAGE_CONFIG: 'homepage/fetch-config-error',
};

export const commonErrorCodes = {
  ERROR_ON_LOADING_TIFF_IMAGE: 'common/tiff-image/load-error',
};

export default errorCode;
