import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import SummaryTab from '../Summary/SummaryTab';
import Details from './Details';
import ReasonBehindStatus from './ReasonBehindStatus';
import { updateCurrentTab } from '../../../reducers/appDetails';
import { RECORD_PAGE_TABS } from '../../../constants/record';

function RecordBody({
  transactionData,
}) {
  const recordData = useSelector((state) => state.appDetails.recordData);
  const rawRecordData = useSelector((state) => state.appDetails.rawRecordData);

  const dispatch = useDispatch();

  const currentTab = useSelector((state) => state.appDetails.currentTab);

  const onAllModulesButtonClick = () => {
    dispatch(updateCurrentTab(RECORD_PAGE_TABS.ALL_MODULES));
  };

  const getCurrentTabElement = () => {
    switch (currentTab) {
      case RECORD_PAGE_TABS.OVERVIEW:
        return (
          <>
            <ReasonBehindStatus
              transactionData={transactionData}
            />
            <div id="record__body__all_modules_button" onClick={onAllModulesButtonClick} aria-hidden>View all modules</div>
          </>
        );
      case RECORD_PAGE_TABS.ALL_MODULES:
        return (
          <Details
            processedRecordData={recordData}
            transactionData={transactionData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div id="record__body">
      <SummaryTab
        transactionData={transactionData}
        recordData={rawRecordData}
      />
      {getCurrentTabElement()}
    </div>
  );
}

RecordBody.propTypes = {
  transactionData: PropTypes.object.isRequired,
};

export default RecordBody;
