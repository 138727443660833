import React from 'react';
import PropTypes from 'prop-types';

function DividerLine({ id }) {
  return (
    <div
      id={id}
    />
  );
}

DividerLine.defaultProps = {
  id: 'divider-line',
};

DividerLine.propTypes = {
  id: PropTypes.string,
};

export default DividerLine;
