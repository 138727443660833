import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { RECORD_PAGE_TABS } from '../../../../constants/record';
import LeftPanelItem from '../LeftPanelItem';

function ModulesUsed({ moduleData, onModuleClick, selectedModule }) {
  const currentTab = useSelector((state) => state.appDetails.currentTab);

  if (currentTab !== RECORD_PAGE_TABS.ALL_MODULES) {
    return null;
  }

  return (
    <>
      {currentTab === RECORD_PAGE_TABS.ALL_MODULES && (
      <>
        <h2>Modules Used</h2>
        <div id="leftPanel__body__modules">
          {!isEmpty(moduleData) ? (
            Object.entries(moduleData).map(
              ([moduleId, { moduleName, isSuccess, subType }]) => (
                <LeftPanelItem
                  moduleName={moduleName}
                  moduleId={moduleId}
                  subType={subType}
                  isSuccess={isSuccess}
                  onModuleClick={onModuleClick}
                  selectedModule={selectedModule}
                  key={moduleId}
                />
              ),
            )
          ) : (
            <span id="leftPanel__body__no_module">No modules attempted</span>
          )}
        </div>
      </>
      )}
    </>
  );
}

ModulesUsed.propTypes = {
  moduleData: PropTypes.object.isRequired,
  onModuleClick: PropTypes.func.isRequired,
  selectedModule: PropTypes.string.isRequired,
};

export default ModulesUsed;
