const showApplicationStatus = (val) => {
  const statusInfo = {};
  switch (val) {
    case 'APPROVED_BY_AGENT':
      statusInfo.status = 'manually approved';
      statusInfo.style = 'approved';
      break;
    case 'DECLINED_BY_AGENT':
      statusInfo.status = 'manually declined';
      statusInfo.style = 'declined';
      break;
    case 'APPLICATION_IN_PROGRESS':
      statusInfo.status = 'started';
      statusInfo.style = 'applying';
      break;
    case 'NEEDS_REVIEW':
      statusInfo.status = 'needs review';
      statusInfo.style = 'review';
      break;
    case 'AUTO_APPROVED':
      statusInfo.status = 'auto approved';
      statusInfo.style = 'approved';
      break;
    case 'AUTO_DECLINED':
      statusInfo.status = 'auto declined';
      statusInfo.style = 'declined';
      break;
    case 'AUTO_REJECT':
      statusInfo.status = 'auto declined';
      statusInfo.style = 'declined';
      break;
    case 'USER_DROPPED_OFF':
      statusInfo.status = 'user cancelled';
      statusInfo.style = 'dropped';
      break;
    case 'manually_approved':
      statusInfo.status = 'manually approved';
      statusInfo.style = 'approved';
      break;
    case 'manually_declined':
      statusInfo.status = 'manually declined';
      statusInfo.style = 'declined';
      break;
    case 'started':
      statusInfo.status = 'started';
      statusInfo.style = 'applying';
      break;
    case 'needs_review':
      statusInfo.status = 'needs review';
      statusInfo.style = 'review';
      break;
    case 'auto_approved':
      statusInfo.status = 'auto approved';
      statusInfo.style = 'approved';
      break;
    case 'auto_declined':
      statusInfo.status = 'auto declined';
      statusInfo.style = 'declined';
      break;
    case 'user_cancelled':
      statusInfo.status = 'user cancelled';
      statusInfo.style = 'dropped';
      break;
    case 'kyc_in_progress':
      statusInfo.status = 'status unavailable';
      statusInfo.style = 'dropped';
      break;
    case 'error':
      statusInfo.status = 'error';
      statusInfo.style = 'dropped';
      break;
    case 'user_resumed':
      statusInfo.status = 'user resumed';
      statusInfo.style = 'applying';
      break;
    default:
      statusInfo.status = 'reported by agent';
      statusInfo.style = 'reported';
      break;
  }
  return statusInfo;
};

export default showApplicationStatus;
