/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Title from '../Title';
import recursivelyRemoveEmptyNodes from '../helpers';

function Row({
  children, id, elementKey, title, componentStyles, gridRow, gridCol, checkChildrenExists,
}) {
  useEffect(() => {
    if (checkChildrenExists) {
      const rowContainerId = `${id}_container`;
      const element = document.getElementById(rowContainerId);
      recursivelyRemoveEmptyNodes(element);
    }
  }, [children, checkChildrenExists]);

  if (!children) return null;

  const customStyles = {
    ...componentStyles,
    ...(gridRow && gridCol ? { gridArea: `${gridRow} / ${gridCol}` } : {}),
  };

  return (
    <div data-hj-suppress style={customStyles} id={`${id}_container`}>
      <Title text={title} />
      <div key={elementKey} id={id} className="generic_card_v2__row">
        {children}
      </div>
    </div>
  );
}

Row.defaultProps = {
  componentStyles: {},
  gridRow: undefined,
  gridCol: undefined,
  checkChildrenExists: false,
};

Row.propTypes = {
  children: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  elementKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  componentStyles: PropTypes.object,
  gridRow: PropTypes.string,
  gridCol: PropTypes.string,
  checkChildrenExists: PropTypes.bool,
};

export default Row;
