function recursivelyRemoveEmptyNodes(element) {
  if (!element) return;

  const childNodes = Array.from(element.children);

  childNodes.forEach((child) => recursivelyRemoveEmptyNodes(child));

  // List of tags that should not be removed as they do not have meaningful child elements
  const skipTags = ['svg', 'path', 'img', 'video', 'input'];

  // List of classes that should not be removed, e.g., components of video players
  const skipClasses = [
    'generic_card_v2__collapsible_icon',
    'MuiSlider-track',
    'MuiSlider-thumb',
    'MuiSlider-thumbSizeMedium',
    'MuiSlider-root',
    'MuiSlider-rail',
  ];

  // List of IDs that should not be removed, e.g., video player containers
  const skipIds = ['video_player__player'];

  // Skip removing the element if it matches a protected tag, class, or ID
  if (
    skipTags.includes(element.tagName.toLowerCase())
      || skipClasses.some((className) => element.classList
        .contains(className))
      || skipIds.includes(element.id)
  ) {
    return; // Skip processing this element
  }

  // Special case: Skip removing children of the video player container
  if (
    element?.parentNode
      && element?.parentNode?.id === 'video_player__player'
  ) {
    return;
  }
  // Remove the element if it has no children
  if (element?.children?.length === 0 && !element?.textContent?.trim()) {
    element?.parentNode?.removeChild(element);
    return;
  }

  // List of header-related classes that might wrap unnecessary containers
  const headerClasses = [
    'generic_card_v2__title',
    'generic_card_v2__collapsible_header',
  ];

  // Remove the container if it only contains a single child that is a header
  if (
    childNodes.length === 1
      && headerClasses.some((className) => childNodes[0]
        .classList.contains(className))
  ) {
    element.parentNode?.removeChild(element);
  }
}

export default recursivelyRemoveEmptyNodes;
