import React from 'react';

import PropTypes from 'prop-types';
import { getProcessedSrcAndFileExtension, isExtensionTiff } from './utils';
import PDFRender from '../../../Common/PDF/PDFRender';
import ImageZoom from '../../../Common/Image/ImageZoom';
import TiffZoom from '../../../Common/Image/TiffZoom';

const DEFAULT_PDF_HEIGHT = '240px';
const DEFAULT_IMAGE_HEIGHT = '240px';

function MediaWrapper({
  id, className, src, onImageClick, onPDFClick, height, width,
}) {
  const { src: updatedSrc, fileExtension } = getProcessedSrcAndFileExtension(src) || {};

  if (fileExtension === 'pdf') {
    return (
      <PDFRender
        id={id}
        className={className}
        src={updatedSrc}
        onClick={onPDFClick}
        showControls={false}
        height={height || DEFAULT_PDF_HEIGHT}
        width={width}
      />
    );
  }

  if (isExtensionTiff(fileExtension)) {
    return (
      <TiffZoom
        id={id}
        className={className}
        src={updatedSrc}
        onClick={onImageClick}
        height={height || DEFAULT_PDF_HEIGHT}
        width={width}
      />
    );
  }

  return (
    <ImageZoom
      id={id}
      className={className}
      src={updatedSrc}
      onClick={onImageClick}
      height={height || DEFAULT_IMAGE_HEIGHT}
      width={width}
    />
  );
}

MediaWrapper.defaultProps = {
  id: '',
  className: '',
  onImageClick: () => { },
  onPDFClick: () => { },
  height: null,
  width: null,
};

MediaWrapper.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  onImageClick: PropTypes.func,
  onPDFClick: PropTypes.func,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default MediaWrapper;
