import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './LeftPanel.scss';
import { useSelector } from 'react-redux';
import LeftPanelHeader from './LeftPanelHeader';
import LeftPanelBody from './LeftPanelBody';

function LeftPanel({ selectedModule, handleModuleChange }) {
  const isApplicationDetailsCollapsibleOpened = useSelector(
    (state) => state.appDetails.isApplicationDetailsCollapsibleOpened,
  );
  const isReasonBehindStatusCollapsibleOpened = useSelector(
    (state) => state.appDetails.isReasonBehindStatusCollapsibleOpened,
  );

  const onModuleClick = (moduleId) => {
    handleModuleChange(moduleId);
  };

  useEffect(() => {
    if (!isApplicationDetailsCollapsibleOpened || !isReasonBehindStatusCollapsibleOpened) {
      onModuleClick('');
    }
  }, [isApplicationDetailsCollapsibleOpened, isReasonBehindStatusCollapsibleOpened]);

  // TODO: Remove this once testing is done on feature deployment @prakasam
  // useEffect(() => {
  //   const selectedModuleElement = document?.querySelector('#leftPanel__body__item.active');
  //   selectedModuleElement?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
  // }, [
  //   selectedModule,
  // ]);

  return (
    <div id="record__left_panel">
      <div id="leftPanel__container">
        <LeftPanelHeader
          selectedModule={selectedModule}
          onModuleClick={handleModuleChange}
        />
        <LeftPanelBody
          selectedModule={selectedModule}
          onModuleClick={handleModuleChange}
        />
      </div>
    </div>
  );
}

LeftPanel.propTypes = {
  selectedModule: PropTypes.string.isRequired,
  handleModuleChange: PropTypes.func.isRequired,
};
export default LeftPanel;
