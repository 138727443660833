import React from 'react';
import cracker from '../../../../../assests/icons/cracker.svg';

function AutoApproved() {
  return (
    <div id="reason_behind_status_container__auto_approved">
      <div id="reason_behind_status_container__auto_approved__icon_section">
        <div>
          <img src={cracker} alt="yeahh!" />
        </div>
      </div>
      <div id="reason_behind_status_container__auto_approved__content_section">
        <span>Woohoo!</span>
        <p>No Issues faced by the user</p>
      </div>
    </div>
  );
}

export default AutoApproved;
