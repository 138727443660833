import React from 'react';
import PropTypes from 'prop-types';
import ShareLink from '../../../assests/icons/shareLink.svg';

function ShareApplications({
  onCopyApplicationLink,
}) {
  return (
    <div id="header__right__share_applications" onClick={onCopyApplicationLink} aria-hidden>
      <div id="header__right__share_applications__icon">
        <img src={ShareLink} alt="share" />
      </div>
      <div id="header__right__share_applications__text">
        <p>Copy Link</p>
      </div>
    </div>
  );
}

ShareApplications.propTypes = {
  onCopyApplicationLink: PropTypes.func.isRequired,
};

export default ShareApplications;
