import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { updateCurrentTab } from '../../../reducers/appDetails';

import DividerLine from '../../Common/DividerLine';

function HeaderNavigation({ tabOptions }) {
  const dispatch = useDispatch();
  const currentTab = useSelector((state) => state.appDetails.currentTab) || '';

  const updateTab = (tab) => {
    dispatch(updateCurrentTab(tab));
  };

  return (
    <div id="record__navigation">
      {tabOptions.map((option, index) => (
        <>
          <div
            id="record__navigation__item"
            onClick={() => updateTab(option.value)}
            key={option.value}
            aria-hidden="true"
          >
            <p>{option.label}</p>
            {currentTab === option.value && <div id="record__navigation__item__active">{option.label}</div>}
          </div>
          {index < tabOptions.length - 1 && (
            <DividerLine
              id="record__navigation__divider"
            />
          )}
        </>
      ))}
    </div>
  );
}

HeaderNavigation.propTypes = {
  tabOptions: PropTypes.array.isRequired,
};

export default HeaderNavigation;
