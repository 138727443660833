/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'summary',
  initialState: {
    isUserDetailsOpen: false,
  },
  reducers: {
    updateUserDetailsOpenState: (state, action) => {
      state.isUserDetailsOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserDetailsOpenState,
} = dataSlice.actions;
export default dataSlice.reducer;
