import { get, isEmpty } from 'lodash';
import { getImageTypesBasedOnConfig } from '../../Summary/utils';
import ordinalSuffix from '../../../../utils/helpers';
import { RECORD_PAGE_TABS } from '../../../../constants/record';

const setImageTypeBasedOnConfig = (data) => data.map((item) => {
//   Example
//   item.endpointModule.imageTypes = {
//     image: {
//       condition: 'request.maskAadhaar === "no"',
//     },
//     cropped: {
//       condition: 'request.maskAadhaar === "yes" && request.maskAadhaar === "yes"',
//     },
//   };
  const imageTypes = get(item, 'endpointModule.imageTypes', null);
  const requestData = get(item, 'extractedReqData[0][0]', {});
  const finalImageType = getImageTypesBasedOnConfig(imageTypes, requestData, false);
  return { ...item, imageTypeToShow: finalImageType };
});

export const recordsToRender = (records, currentTab) => {
  if (isEmpty(records)) {
    return [];
  }
  if (currentTab === RECORD_PAGE_TABS.ALL_MODULES) {
    return records;
  }
  return records.length > 1 ? [records[0]] : records;
};

export const getRecordsCounts = (records) => records.reduce((accumulator, { isMissing }) => {
  accumulator.total += 1;
  if (isMissing) {
    accumulator.missing += 1;
  } else {
    accumulator.success += 1;
  }
  return accumulator;
}, { total: 0, missing: 0, success: 0 });

export const getToolTipTextForMissingAttempts = (total, success) => {
  const startAttempt = total - success;
  if (startAttempt === 1) {
    return '1st attempt';
  }
  return `${ordinalSuffix(total - success)} - 1st attempt`;
};

export default setImageTypeBasedOnConfig;
