import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import MediaCarousel from '../../Common/Image/MediaCarousel';
import ImageViewerOpenWrapper from '../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import VideoPlayerOpenWrapper from '../../Common/Video/VideoPlayerOpenWrapper';
import PDFViewerOpenWrapper from '../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';
import CarrotDownIcon from '../../../assests/icons/carrotDown04.svg';

function SummaryMedia({
  images, videos, pdfs, isExpanded,
}) {
  const [slideShowImageIndex, setSlideShowImageIndex] = useState(0);
  const [PDFIndex, setPDFIndex] = useState(0);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [videoSelected, setVideoSelected] = useState(null);
  const [isMediaOpened, setIsMediaOpened] = useState(isExpanded);

  const onVideoClick = (index) => {
    setVideoSelected(index);
    setShowVideoPlayer(true);
  };

  const onClosePlayer = () => setShowVideoPlayer(false);

  const onCloseViewer = () => {
    setShowImageViewer(false);
  };

  const onImageClick = (index) => {
    setSlideShowImageIndex(index);
    setShowImageViewer(true);
  };

  const onPDFClick = (index) => {
    setPDFIndex(index);
    setShowPDFViewer(true);
  };

  const onClosePDFViewer = () => {
    setShowPDFViewer(false);
  };

  const toggleMediaState = () => {
    setIsMediaOpened((prev) => !prev);
  };

  return (
    <div id="summary__image_container">
      <button id="summary__image_header" type="button" className={`${isMediaOpened && 'active'}`} onClick={toggleMediaState}>
        Images & Videos Collected
        {' '}
        (
        {images.length + videos.length}
        )
        <img style={{ marginLeft: '4px' }} src={CarrotDownIcon} alt="" />
      </button>
      {isMediaOpened && (
        <>
          <MediaCarousel
            id="summary__image_carousal"
            images={images}
            videos={videos}
            pdfs={pdfs}
            onImageClick={onImageClick}
            onVideoClick={onVideoClick}
            onPDFClick={onPDFClick}
          />
          <ImageViewerOpenWrapper
            open={showImageViewer}
            images={images}
            defaultImageIndex={slideShowImageIndex}
            closeViewerHandler={onCloseViewer}
          />
          <VideoPlayerOpenWrapper
            open={showVideoPlayer}
            videos={videos}
            defaultVideoIndex={videoSelected}
            closeViewerHandler={onClosePlayer}
          />
          <PDFViewerOpenWrapper
            open={showPDFViewer}
            pdf={get(pdfs, PDFIndex, {})}
            closeViewerHandler={onClosePDFViewer}
          />
        </>
      )}
    </div>
  );
}

SummaryMedia.defaultProps = {
  images: [],
  videos: [],
  pdfs: [],
  isExpanded: false,
};

SummaryMedia.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  pdfs: PropTypes.array,
  isExpanded: PropTypes.bool,
};

export default SummaryMedia;
