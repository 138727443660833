import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomTabs from '../../../Common/Utils/CustomTabs';
import '../../Details.scss';
import VideoCardPanel from '../Panels/VideoCardPanel';
import CommonHeader from './CommonHeader';
import { recordsToRender } from './utils';

// We should be getting the subType from parent component
// once we have cleaned up the logic for this flow
const VideoCard = ({
  moduleName, records, hideHeader, hideTabs, hideCreatedAtAndStatus,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [videoRecordingsArray, setVideoRecordingsArray] = useState([]);
  const currentTabToView = useSelector((state) => state.appDetails.currentTab) || '';

  useEffect(() => {
    setTabIndex(0);

    const filteredRecords = recordsToRender(records, currentTabToView);
    setVideoRecordingsArray(filteredRecords);
  }, [records, currentTabToView]);

  return (
    <div id="video_details__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType="videoCardType1" />}
      <div id="video_details__container__section">
        {!hideTabs && (
        <CustomTabs
          id="details__body_tabs"
          tabArray={videoRecordingsArray}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
        )}
        {videoRecordingsArray.map((item, index) => (
          <VideoCardPanel
            key={item?.id}
            item={item}
            index={index}
            value={tabIndex}
            hideCreatedAtAndStatus={hideCreatedAtAndStatus}
          />
        ))}
      </div>
    </div>
  );
};

VideoCard.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

VideoCard.propTypes = {
  moduleName: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default VideoCard;
