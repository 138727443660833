/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { last, get } from 'lodash';
import { Spinner } from 'clm-components';
import { useSelector } from 'react-redux';
import RightPanel from './RightPanel/RightPanel';
import RecordBody from './Body/RecordBody';
import LeftPanel from './LeftPanel/LeftPanel';
import ReviewCard from './RightPanel/ReviewCard/ReviewCard';
import CommentBody from './RightPanel/Comment/CommentBody';
import { summaryModuleId } from './RecordUtils';
import { scrollElementIntoViewById } from '../../utils/scrollUtils';
import SubPanelWrapper from './RightPanel/SubPanelWrapper';
import ReviewTagsCard from './RightPanel/ReviewTagsCard/ReviewTagsCard';
import ReviewButtons from './RightPanel/ReviewButtons/ReviewButtons';
import TimelineView from './Body/TimelineView';
import ManualAssignmentCard from '../ManualAssignment/Record/cards/ManualAssignmentCard';
import Modal from '../ManualAssignment/common/Modal/Modal';
import ModalBody from '../ManualAssignment/Record/ModalBody';
import { constants } from '../../config';
import { RECORD_PAGE_TABS } from '../../constants/record';

function RecordContent({
  loading,
  comments,
  addNewComment,
  updateApplicationStatus,
  workflowModulesData,
  onChangeAssigneeModalClose,
  selectedAssignee,
  onSelectAssignee,
  onAssigneeChange,
  onReAssignment,
}) {
  const selectedModuleToScroll = useSelector((state) => get(state, 'appDetails.selectedModuleToScroll', ''));
  const [selectedModule, setSelectedModule] = useState(
    selectedModuleToScroll || summaryModuleId,
  );
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const applicationManualReviewAssignment = useSelector((state) => get(state, 'config.applicationManualReviewAssignment', 'none'));

  const transactionData = useSelector((state) => get(state, 'appDetails.transactionData', {}));
  const currentTab = useSelector((state) => get(state, 'appDetails.currentTab', RECORD_PAGE_TABS.OVERVIEW));

  const { status } = transactionData;

  const isTimeLineView = currentTab === RECORD_PAGE_TABS.TIMELINE;

  const handleModuleChange = async (moduleId) => {
    setSelectedModule(moduleId);
    setIsAutoScrolling(true);
    await scrollElementIntoViewById('record__content', moduleId);
    setIsAutoScrolling(false);
  };

  useEffect(() => {
    if (selectedModuleToScroll) {
      handleModuleChange(selectedModuleToScroll);
    }
  }, [selectedModuleToScroll]);

  const showManualAssignmentCard = () => {
    if (
      applicationManualReviewAssignment !== 'none'
      && constants.STATUSES_TO_SHOW_CHANGE_ASSIGNEE_CARD.includes(status)
    ) {
      return true;
    }
    return false;
  };

  const scrollHandler = (event) => {
    // Skip scroll handling if auto-scrolling is in progress
    if (isAutoScrolling) return;

    const TOP_OFFSET = 130; // Offset from top of viewport
    const bottomMargin = 20; // Margin from bottom of viewport

    // Get all direct div children from the main record body
    const bodyChildrenDivArray = Array.from(
      document.querySelectorAll('#record__body > div'),
    );

    let selectedModuleId = selectedModule;

    // Get additional sections by their specific names
    const reasonBehindStatus = Array.from(
      document.getElementsByName('reason_behind_status_modules'),
    );
    const applicationDetails = Array.from(
      document.getElementsByName('application_details_and_user_information'),
    );

    // Get all child elements from these sections
    // doing this because we want to get all the children that is displayed on the screen (these are the elements that are visible but inside an accordion)
    const reasonBehindStatusChildren = reasonBehindStatus.flatMap((element) => Array.from(element.children));
    const applicationDetailsChildren = applicationDetails.flatMap((element) => Array.from(element.children));

    // Combine all elements into one array
    const allElements = [
      ...applicationDetailsChildren,
      ...reasonBehindStatusChildren,
      ...bodyChildrenDivArray,
    ];

    // Remove duplicate elements based on their IDs
    const uniqueElements = allElements.filter(
      (element, index, self) => element !== null
        && self.findIndex((e) => e?.id === element?.id) === index,
    );

    // Remove specific container elements we don't want to track
    const elementIdsToRemove = [
      'reason_behind_status_container',
      'record__body__all_modules',
    ];
    const filteredElements = uniqueElements.filter(
      (element) => !elementIdsToRemove.includes(element.id),
    );

    // Find which element is currently in view
    // Uses .every() to break the loop early when we find our target (by returning false)
    filteredElements.every((child) => {
      // Check if the current scroll position is within the top half of an element
      if (
        event.target.scrollTop + TOP_OFFSET >= child?.offsetTop
        && event.target.scrollTop + TOP_OFFSET
          <= child?.offsetTop + child?.offsetHeight / 2
        && event.target.scrollTop + TOP_OFFSET
          < child?.offsetTop + child?.offsetHeight
      ) {
        selectedModuleId = child.id;
        return false; // Break the loop
      }
      return true; // Continue checking
    });

    // Special case: if we're at the bottom of the page, select the last element
    const lastElement = last(bodyChildrenDivArray);
    if (
      event.target.scrollTop
        + event.target.offsetHeight
        + bottomMargin
        + TOP_OFFSET
      >= lastElement?.offsetTop + lastElement?.offsetHeight
    ) {
      selectedModuleId = lastElement.id;
    }

    // Update the selected module in state
    setSelectedModule(selectedModuleId);
  };

  if (loading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    );
  }

  return (
    <div
      id="record__content"
      onScroll={scrollHandler}
      className={currentTab}
    >
      {isTimeLineView ? (
        <>
          <div id="current-date" className="hidden" />
          <TimelineView
            workflowModulesData={workflowModulesData}
            applicationStatus={transactionData.status}
          />
        </>
      ) : (
        <>
          <LeftPanel
            selectedModule={selectedModule}
            handleModuleChange={handleModuleChange}
          />
          <RecordBody transactionData={transactionData} />
        </>
      )}
      <RightPanel>
        {showManualAssignmentCard() && (
          <>
            <ManualAssignmentCard
              onFilterAdd={onSelectAssignee}
              selectedAssignee={selectedAssignee}
              currentAssignee={transactionData.reviewerEmail}
            />
            <Modal
              isOpen={selectedAssignee}
              onClose={onChangeAssigneeModalClose}
              onSubmit={onAssigneeChange}
              header="Confirm Application Assignment"
              id="Manual_assigment_modal_recordcontent"
            >
              <ModalBody assigneeEmail={selectedAssignee} />
            </Modal>
          </>
        )}
        <ReviewCard
          transactionData={transactionData}
          selectedModule={selectedModule}
          handleModuleChange={handleModuleChange}
        />
        <SubPanelWrapper>
          <ReviewTagsCard />
          <CommentBody comments={comments} addNewComment={addNewComment} />
          <ReviewButtons
            onChangeAssingeeForReAssignment={onSelectAssignee}
            onReAssignment={onReAssignment}
            updateApplicationStatus={updateApplicationStatus}
            applicationStatus={transactionData.status}
            reviewerEmail={transactionData.reviewerEmail}
          />
        </SubPanelWrapper>
      </RightPanel>
    </div>
  );
}

RecordContent.defaultProps = {
  onChangeAssigneeModalClose: () => {},
  onSelectAssignee: () => {},
  onAssigneeChange: () => {},
  selectedAssignee: '',
};

RecordContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.any).isRequired,
  updateApplicationStatus: PropTypes.func.isRequired,
  addNewComment: PropTypes.func.isRequired,
  workflowModulesData: PropTypes.array.isRequired,
  onChangeAssigneeModalClose: PropTypes.func,
  onSelectAssignee: PropTypes.func,
  onAssigneeChange: PropTypes.func,
  selectedAssignee: PropTypes.string,
  onReAssignment: PropTypes.func.isRequired,
};

export default RecordContent;
