import * as Sentry from '@sentry/react';
import { isString } from 'lodash';
import UTIF from 'utif';
import { commonErrorCodes } from '../../../constants/errorCode';

export const getImageScaleTransform = (
  containerRef,
  scaleRef,
  imageRotationAngle,
  imageScaleSize,
  isVerticalImage,
) => {
  // Translate image if overflows container to avoid cutting off
  const isImageRotatedHorizontally = imageRotationAngle === 90
    || imageRotationAngle === 270
    || imageRotationAngle === -90
    || imageRotationAngle === -270;
  const containerWidth = containerRef.current.offsetWidth;
  const scaleWidth = (isVerticalImage && isImageRotatedHorizontally
    ? scaleRef.current.offsetHeight
    : scaleRef.current.offsetWidth) * imageScaleSize;
  if (scaleWidth > containerWidth) {
    return `translateX(${
      (scaleWidth - containerWidth) / 2
    }px) scale(${imageScaleSize})`;
  }
  return `scale(${imageScaleSize})`;
};

export const getImageRotationTransform = (imageRef,
  imageRotationAngle,
  isVerticalImage) => {
  let transform;
  let transformOrigin;
  if (isVerticalImage) {
    // Handle vertical image cutting off when rotating 180deg
    const isImageRotatedHorizontally = imageRotationAngle === 180 || imageRotationAngle === -180;
    transformOrigin = isImageRotatedHorizontally ? 'top' : '50% 50% 0';
    transform = `${isImageRotatedHorizontally ? 'translate(0%, 100%)' : ''} rotate(${imageRotationAngle}deg)`;
  } else {
    // Handle horizontal image cutting off when rotating 90deg and 270deg
    const imageCurrentWidth = imageRef.current.width;
    const imageCurrentHeight = imageRef.current.height;
    if (imageRotationAngle === 90 || imageRotationAngle === -270) {
      transformOrigin = 'top left 0';
      transform = `translateX(${(imageCurrentWidth / 2 + imageCurrentHeight / 2)}px) rotate(${imageRotationAngle}deg)`;
    } else if (imageRotationAngle === 270 || imageRotationAngle === -90) {
      transformOrigin = 'top right 0';
      transform = `rotate(-90deg) translateY(-${(imageCurrentWidth / 2 + imageCurrentHeight / 2)}px)`;
    } else {
      transformOrigin = '50% 50% 0';
      transform = `rotate(${imageRotationAngle}deg)`;
    }
  }
  return { transform, transformOrigin };
};

export const loadTiff = async ({ src, height, canvasRef }) => {
  try {
    if (!src) return;

    const response = await fetch(src);
    if (!response.ok) {
      const errorToThrow = new Error('Failed to fetch tiff image');
      errorToThrow.response = response;
      throw errorToThrow;
    }

    const buffer = await response.arrayBuffer();
    const [ifd] = UTIF.decode(buffer);
    UTIF.decodeImage(buffer, ifd);
    const rgba = UTIF.toRGBA8(ifd);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Calculate dimensions maintaining aspect ratio
    const aspectRatio = ifd.width / ifd.height;
    const containerWidth = canvas.parentElement.clientWidth;

    // Parse height if it's a string with "px"
    const numericHeight = isString(height) ? parseInt(height.replace('px', ''), 10) : null;
    const calculatedHeight = numericHeight || containerWidth / aspectRatio;
    const calculatedWidth = containerWidth;

    // Set canvas dimensions
    canvas.width = calculatedWidth;
    canvas.height = calculatedHeight;

    // Create temporary canvas for original image
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = ifd.width;
    tempCanvas.height = ifd.height;
    const tempCtx = tempCanvas.getContext('2d');

    // Draw original image data to temp canvas
    const img = tempCtx.createImageData(ifd.width, ifd.height);
    img.data.set(rgba);
    tempCtx.putImageData(img, 0, 0);

    // Scale and draw to main canvas
    ctx.drawImage(tempCanvas, 0, 0, calculatedWidth, calculatedHeight);
  } catch (error) {
    Sentry.captureException(`${commonErrorCodes.ERROR_ON_LOADING_TIFF_IMAGE} - ${error}`);
  }
};
