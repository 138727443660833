import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import Flags from './Flags';
import Header from './Header';
import SdkError from './SdkError';
import { APPLICATION_STATUS_MAP } from '../../../../constants/applicationStatus';
import FailureReason from './FailureReason';
import ReasonBehindTheStatus from './ReasonBehindTheStatus';

function ReviewCard({
  transactionData, selectedModule, handleModuleChange,
}) {
  const failureReason = useSelector((state) => state.appDetails.failureReason);
  const flagData = useSelector((state) => state.appDetails.flagData);
  const v2FlagData = useSelector((state) => state.appDetails.v2FlagData);
  const { status, sdkError } = transactionData;

  const reasonBehindTheStatusModules = useSelector(
    (state) => state.appDetails.reasonBehindTheStatusModules,
  ) || [];

  useEffect(() => {
    const selectedModuleElement = document?.querySelector('#leftPanel__body__item.active');
    selectedModuleElement?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
  }, [
    selectedModule,
  ]);

  const getReviewCardComponent = () => {
    if (failureReason && !isEmpty(reasonBehindTheStatusModules)) {
      return (
        <ReasonBehindTheStatus
          failureReason={failureReason}
          reasonBehindTheStatusModules={reasonBehindTheStatusModules}
          handleModuleChange={handleModuleChange}
        />
      );
    }

    if (status === APPLICATION_STATUS_MAP.ERROR) {
      return <SdkError errorMsg={sdkError} />;
    }

    if (failureReason) {
      return <FailureReason failureReason={failureReason} />;
    }

    return (
      <Flags
        flags={flagData}
        v2Flags={v2FlagData}
        selectedModule={selectedModule}
        handleModuleChange={handleModuleChange}
      />
    );
  };
  return (
    <div id="right_panel__review_card__container">
      <Header status={status} />
      {getReviewCardComponent()}
    </div>
  );
}

ReviewCard.propTypes = {
  transactionData: PropTypes.object.isRequired,
  selectedModule: PropTypes.string.isRequired,
  handleModuleChange: PropTypes.func.isRequired,
};

export default ReviewCard;
