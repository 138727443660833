import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import getComponentForCardType, { formatRecordsForCardTypes } from './utils';
import DetailsCardErrorBoundary from './DetailsCardErrorBoundary';
import { RECORD_PAGE_TABS } from '../../../constants/record';

import '../Details.scss';
import EmptyRecord from '../EmptyRecord';

function Details({
  processedRecordData, transactionData,
}) {
  const currentTab = useSelector((state) => state.appDetails.currentTab) || '';
  if (isEmpty(processedRecordData)) return <EmptyRecord />;

  return (
    <>
      {Object.entries(processedRecordData).map(
        ([moduleId, {
          type, subType, moduleName, records,
        }]) => (
          <DetailsCardErrorBoundary key={moduleId} moduleId={moduleId} moduleName={moduleName}>
            <div id={moduleId} className={currentTab === RECORD_PAGE_TABS.OVERVIEW && 'overview__details__card__shadow'}>
              {React.cloneElement(getComponentForCardType(type), {
                moduleId,
                moduleName,
                subType,
                records: formatRecordsForCardTypes(type, records),
                transactionData,
              })}
            </div>
          </DetailsCardErrorBoundary>
        ),
      )}
    </>
  );
}

Details.propTypes = {
  processedRecordData: PropTypes.object.isRequired,
  transactionData: PropTypes.object.isRequired,
};
export default React.memo(Details);
