import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import AutoApproved from './AutoApproved';
import UserCancelled from './UserCancelled';
import Details from '../../Details';
import { END_STATUSES } from '../../../../../constants/record';

function ReasonBehindTheStatusNotEmptyState({ processedRecordData, transactionData }) {
  const transaction = useSelector((state) => get(state, 'appDetails.transactionData', {}));
  const status = get(transaction, 'status', '');

  if (isEmpty(status)) {
    return null;
  }

  if (status === END_STATUSES.USER_CANCELLED) {
    return <UserCancelled />;
  }

  if (status === END_STATUSES.AUTO_APPROVED) {
    return <AutoApproved />;
  }

  return (
    <Details
      processedRecordData={processedRecordData}
      transactionData={transactionData}
    />
  );
}
ReasonBehindTheStatusNotEmptyState.propTypes = {
  processedRecordData: PropTypes.array.isRequired,
  transactionData: PropTypes.object.isRequired,
};

export default ReasonBehindTheStatusNotEmptyState;
