import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  get, values, set, isEmpty,
} from 'lodash';
import { Spinner } from 'clm-components';
import * as Sentry from '@sentry/react';
import useShowErrorAlert, { useFormatPerformanceAnalyticsData } from '../../utils/lib';
import useS3Config from '../../utils/hooks/s3Config';
import Body from '../../components/Account/PricingInfo/Body';
import './PricingInfo.scss';
import { getClientInfo } from '../../api/usage';
import AlertCode from '../../constants/alertCodes';
import errorCode from '../../constants/errorCode';
import { PERFORMANCE_METRIC_EVENTS } from '../../config';
import { isNumber } from '../../utils/helpers';

function PricingInfo() {
  const showErrorAlert = useShowErrorAlert();
  const [moduleUsageConfig, setModuleUsageConfig] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modulePricingSlabLists, setModulePricingSlabLists] = useState([]);
  const [platformPricingSlabLists, setPlatformPricingSlabLists] = useState([]);
  const [MMCList, setMMCList] = useState([]);
  const [currency, setCurrency] = useState('');
  const getModuleUsageConfig = useS3Config('usageConfig');
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();

  const clientId = useSelector((state) => state.user.clientId);

  const getPricing = async () => {
    const startTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/user/company',
    };
    try {
      const response = await getClientInfo(clientId);
      if (isNumber(response.status)) set(eventObj, 'statusCode', response.status);
      const {
        companyDetails,
        businessUnits,
        pricingSlabList,
        platformPricingSlabList,
        minimumMonthlyCommitment,
      } = get(response, 'data.result.data', []);
      const { billingCurrency } = companyDetails || {};
      const currencyValue = billingCurrency || get(values(businessUnits), [0, 'billingCurrency'], '');
      setModulePricingSlabLists(pricingSlabList);
      setPlatformPricingSlabLists(platformPricingSlabList);
      setCurrency(currencyValue);
      if (!isEmpty(minimumMonthlyCommitment)) setMMCList(minimumMonthlyCommitment);
      const obtainedUsageConfig = await getModuleUsageConfig();
      setModuleUsageConfig(obtainedUsageConfig);
    } catch (err) {
      showErrorAlert({ err, message: AlertCode.ERROR_FETCHING_PRICE });
      if (isNumber(err?.response.statusCode)) set(eventObj, 'statusCode', err?.response.statusCode);
      Sentry.captureException(`${errorCode.USAGE_FETCH_INTERNAL_ERROR} - ${err}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_PRICE,
        },
      });
    }
    const processingTime = performance.now() - startTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.ACCOUNT_PAGE_FETCH_PRICING_INFO;
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  useEffect(() => {
    async function getClientInfoAsync() {
      setIsLoading(true);
      await getPricing();
      setIsLoading(false);
    }
    if (clientId) {
      getClientInfoAsync();
    }
  }, []);

  if (isLoading) {
    return <div className="pricing-info-loader"><Spinner /></div>;
  }

  return (
    <div id="client_pricing_info__container">
      <div className="client_pricing_info__header__container">
        <h2>Pricing Information</h2>
      </div>
      <Body
        isLoading={isLoading}
        modulePricingInfo={modulePricingSlabLists}
        platformPricingInfo={platformPricingSlabLists}
        currency={currency}
        usageConfig={moduleUsageConfig}
        minimumMonthlyCommitment={MMCList}
      />
    </div>
  );
}

export default PricingInfo;
