/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import PDFRender from '../PDFRender';

import '../PDF.scss';

function PDFViewer({ id, pdf, closeViewerHandler }) {
  const viewerPDFRef = useRef(null);
  const viewerPDFContainerRef = useRef(null);
  const { src, label } = pdf;

  const handleMouseClick = (event) => {
    if (
      viewerPDFRef.current
      && event.target !== viewerPDFRef.current
      && !viewerPDFRef.current.contains(event.target)
    ) {
      closeViewerHandler();
    }
  };

  if (!src) {
    return '';
  }

  return (
    <div data-hj-suppress id={id} className="common_pdf_viewer__container" onClick={handleMouseClick}>
      <div
        className="common_pdf_viewer__pdf__container"
        ref={viewerPDFContainerRef}
      >
        <div className="common_pdf_viewer__pdf__header">
          {label}
        </div>
        <div className="common_pdf_viewer__pdf" ref={viewerPDFRef}>
          <PDFRender
            src={src}
            closeViewerHandler={closeViewerHandler}
            showControls
          />
        </div>
      </div>
    </div>
  );
}

PDFViewer.propTypes = {
  id: PropTypes.string.isRequired,
  pdf: PropTypes.object.isRequired,
  closeViewerHandler: PropTypes.func.isRequired,
};

export default PDFViewer;
