import React, {
  useEffect, useRef, forwardRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'clm-components';
import { loadTiff } from './imageUtils';
import { DEFAULT_IMAGE_ZOOM_SCALE } from '../../../constants';

const TiffViewer = forwardRef(({
  src, height, zoom, zoomScale, transformOrigin,
}, ref) => {
  const localCanvasRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  // Use the passed ref if available, otherwise use local ref
  const canvasRef = ref || localCanvasRef;

  useEffect(() => {
    const loadImage = async () => {
      setIsLoading(true);
      try {
        await loadTiff({ src, height, canvasRef });
      } finally {
        setIsLoading(false);
      }
    };

    loadImage();
  }, [src, height]);

  return (
    <>
      {isLoading && (
        <div className="common_tiff_loader">
          <Spinner />
        </div>
      )}
      <canvas
        data-hj-suppress
        ref={canvasRef}
        style={{
          opacity: isLoading ? 0 : 1,
          transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)',
          transformOrigin,
        }}
      />
    </>
  );
});

TiffViewer.defaultProps = {
  height: null,
  zoom: false,
  zoomScale: DEFAULT_IMAGE_ZOOM_SCALE,
  transformOrigin: '',
};

TiffViewer.propTypes = {
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  zoom: PropTypes.bool,
  zoomScale: PropTypes.number,
  transformOrigin: PropTypes.string,
};

// Add display name for debugging purposes
TiffViewer.displayName = 'TiffViewer';

export default TiffViewer;
