import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import './PDF.scss';

import { ReactComponent as LeftArrowIcon } from '../../../assests/icons/leftArrow08.svg';
import { ReactComponent as RightArrowIcon } from '../../../assests/icons/rightArrow.svg';
import { ReactComponent as ZoomInIcon } from '../../../assests/icons/zoomIn.svg';
import { ReactComponent as ZoomOutIcon } from '../../../assests/icons/zoomOut.svg';
import { ReactComponent as RotateLeftIcon } from '../../../assests/icons/rotateLeft.svg';
import { ReactComponent as RotateRightIcon } from '../../../assests/icons/rotateRight.svg';

import PDF_RENDER_CONFIG from '../../../constants/pdf';

const {
  PDF_MIN_ZOOM_SCALE, PDF_MAX_ZOOM_SCALE, PDF_SCALE_STEP_SIZE, PDF_ROTATION_STEP,
} = PDF_RENDER_CONFIG;

function PDFRender({
  id,
  className,
  documentClassName,
  pageClassName,
  src,
  showControls,
  onClick,
  height,
  onDocumentLoadComplete,
  closeViewerHandler,
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);
  // Do nothing for now
  const onPassword = () => {};

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    if (pageNumber === 1) return;
    changePage(-1);
  };

  const nextPage = () => {
    if (pageNumber === numPages) return;
    changePage(1);
  };

  const onPageRenderSuccess = () => {
    onDocumentLoadComplete();
  };

  const onPageRenderError = () => {
    onDocumentLoadComplete();
  };

  const onPageLoadError = () => {
    onDocumentLoadComplete();
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const onDocumentLoadError = () => {
    onDocumentLoadComplete();
  };

  const zoomIn = () => {
    if (scale < PDF_MAX_ZOOM_SCALE) {
      setScale(scale + PDF_SCALE_STEP_SIZE);
    }
  };

  const zoomOut = () => {
    if (scale > PDF_MIN_ZOOM_SCALE) {
      setScale(scale - PDF_SCALE_STEP_SIZE);
    }
  };

  const rotateRight = () => {
    setRotation((prevRotation) => (prevRotation + PDF_ROTATION_STEP) % 360);
  };

  const rotateLeft = () => {
    setRotation(
      (prevRotation) => (prevRotation - PDF_ROTATION_STEP + 360) % 360,
    );
  };

  const reset = () => {
    setScale(1);
    setRotation(0);
  };

  return (
    <div
      data-hj-suppress
      id={id}
      style={{ height }}
      className={`common_pdf_renderer__container ${className}`}
      onClick={onClick}
      aria-hidden
    >
      <Document
        file={src}
        className={`common_pdf_renderer__document ${documentClassName}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        onItemClick={onClick}
        noData=""
        loading=""
        error="Error loading PDF"
        onPassword={onPassword}
      >
        <Page
          key={`page-${pageNumber}`}
          pageNumber={pageNumber}
          className={scale === 1 ? `common_pdf_renderer__page ${pageClassName}` : ''}
          onRenderSuccess={onPageRenderSuccess}
          onRenderError={onPageRenderError}
          onLoadError={onPageLoadError}
          loading=""
          scale={scale}
          rotate={rotation}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>

      {showControls && (
        <div className="common_pdf_viewer__control_container">
          <div className="slideshow__control">
            <div className="zoomIn" onClick={zoomIn} aria-hidden>
              <ZoomInIcon />
            </div>
            <div className="zoomOut" onClick={zoomOut} aria-hidden>
              <ZoomOutIcon />
            </div>
            <div className="rotateLeft" onClick={rotateLeft} aria-hidden>
              <RotateLeftIcon />
            </div>
            <div className="rotateRight" onClick={rotateRight} aria-hidden>
              <RotateRightIcon />
            </div>
            <div className="line" />
            <div className="reset" onClick={reset} aria-hidden>
              Reset
            </div>
            <div className="line" />
            <div
              className={`previousImage ${pageNumber === 1 ? 'disabled' : ''}`}
              onClick={previousPage}
              aria-hidden
            >
              <LeftArrowIcon />
            </div>
            <div className="imageIndex">
              {pageNumber}
              /
              {numPages}
            </div>
            <div
              className={`nextImage ${
                pageNumber === numPages ? 'disabled' : ''
              }`}
              onClick={nextPage}
              aria-hidden
            >
              <RightArrowIcon />
            </div>
            <div className="line" />
            <div className="close" onClick={closeViewerHandler} aria-hidden>
              Close
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

PDFRender.defaultProps = {
  id: '',
  className: '',
  documentClassName: '',
  pageClassName: '',
  showControls: true,
  onClick: () => {},
  height: null,
  onDocumentLoadComplete: () => {},
  closeViewerHandler: () => {},
};

PDFRender.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  documentClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  src: PropTypes.string.isRequired,
  showControls: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.string,
  onDocumentLoadComplete: PropTypes.func,
  closeViewerHandler: PropTypes.func,
};

export default PDFRender;
