import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import '../../Details.scss';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';
import MultiTablePanel from '../Panels/MultiTablePanel';
import { recordsToRender } from './utils';

function MultiTableCard({
  moduleName, records, hideHeader, hideTabs, hideCreatedAtAndStatus,
}) {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [filteredRecords, setFilteredRecords] = useState([]);

  const currentTabToView = useSelector((state) => state.appDetails.currentTab) || '';

  useEffect(() => {
    const updatedRecords = recordsToRender(records, currentTabToView);
    setFilteredRecords(updatedRecords);
    setCurrentTabIndex(0);
  }, [records, currentTabToView]);

  return (
    <div id="multi_table_card__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType="multiTableCard" />}
      <div id="multi_table_card__container__section">
        {!hideTabs && (
          <CustomTabs
            id="details__body_tabs"
            tabArray={filteredRecords}
            tabIndex={currentTabIndex}
            setTabIndex={setCurrentTabIndex}
          />
        )}
        {
          filteredRecords.length > 0 && filteredRecords.map(
            (record, index) => (
              <MultiTablePanel
                index={index}
                currentTabIndex={currentTabIndex}
                key={record.requestId}
                record={record}
                hideCreatedAtAndStatus={hideCreatedAtAndStatus}
              />
            ),
          )
        }
      </div>
    </div>
  );
}

MultiTableCard.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

MultiTableCard.propTypes = {
  moduleName: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};

export default MultiTableCard;
