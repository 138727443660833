/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import { get } from 'lodash';
import Section from './Section';
import Row from './Row';
import Column from './Column';
import Table from './Table';
import Media from './Media';
import Accordion from './Accordion';
import Grid from './Grid';
import VideoPlayer from './VideoPlayer';

export const getDataSource = ({
  useRequestData,
  useAdditionalMetaData,
  requestData,
  responseData,
  additionalMetaData,
}) => {
  if (useAdditionalMetaData) {
    return additionalMetaData;
  }
  return useRequestData ? requestData : responseData;
};

const getConditionalData = ({
  condition,
  responseData,
  requestData,
  additionalMetaData,
  useRequestData,
  useAdditionalMetaData,
}) => {
  try {
    const conditionSplit = condition.split(' === ');
    const conditionLHS = conditionSplit[0];
    const conditionRHS = conditionSplit[1].replace(/'/g, '');
    const dataToUse = getDataSource({
      useRequestData,
      useAdditionalMetaData,
      requestData,
      responseData,
      additionalMetaData,
    });

    if (conditionLHS.includes('*')) {
      const conditionArrayPath = conditionLHS.split('.*.')[0];
      const conditionKey = conditionLHS.split('.*.')[1];

      const conditionArray = conditionArrayPath ? get(dataToUse, conditionArrayPath) : dataToUse;

      const result = conditionArray.filter((item) => get(item, conditionKey) === conditionRHS);

      return {
        response: useRequestData ? responseData : result,
        request: useRequestData ? result : requestData,
        metaData: useAdditionalMetaData ? result : additionalMetaData,
      };
    }

    return { request: requestData, response: responseData, metaData: additionalMetaData };
  } catch (error) {
    Sentry.captureException(`Generic card v2 conditional utils. ${error}`);
    return { request: requestData, response: responseData, metaData: additionalMetaData };
  }
};

const GenericComponent = ({
  componentConfig,
  responseData,
  requestData,
  additionalMetaData,
  moduleId,
  index,
  elementKey,
  fileUrls,
}) => {
  const {
    type,
    id,
    styles,
    conditional,
    condition,
    gridRow,
    gridCol,
    useRequestData,
    useAdditionalMetaData,
    children,
    checkChildrenExists,
  } = componentConfig;
  const elementId = `${moduleId}_${id}_${index}`;

  let finalResponseData = responseData;
  let finalRequestData = requestData;
  let finalAdditionalMetaData = additionalMetaData;

  if (conditional) {
    const { response, request, metaData } = getConditionalData({
      condition,
      responseData,
      requestData,
      additionalMetaData,
      useRequestData,
      useAdditionalMetaData,
    });

    finalResponseData = response;
    finalRequestData = request;
    finalAdditionalMetaData = metaData;
  }

  const childrenComponents = children?.map((child, childIndex) => (
    <GenericComponent
      key={elementKey}
      componentConfig={child}
      requestData={finalRequestData}
      responseData={finalResponseData}
      additionalMetaData={finalAdditionalMetaData}
      useAdditionalMetaData={useAdditionalMetaData}
      useRequestData={useRequestData}
      moduleId={moduleId}
      index={index}
      elementKey={`${elementId}_${childIndex}`}
      fileUrls={fileUrls}
    />
  ));

  const dataToUse = getDataSource({
    useRequestData,
    useAdditionalMetaData,
    requestData: finalRequestData,
    responseData: finalResponseData,
    additionalMetaData: finalAdditionalMetaData,
  });

  switch (type) {
    case 'section':
      return (
        <Section
          elementKey={elementKey}
          id={elementId}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
          checkChildrenExists={checkChildrenExists}
        >
          {childrenComponents}
        </Section>
      );

    case 'row':
      const {
        title: rowTitle,
      } = componentConfig;
      return (
        <Row
          elementKey={elementKey}
          id={elementId}
          title={rowTitle}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
          checkChildrenExists={checkChildrenExists}
        >
          {childrenComponents}
        </Row>
      );

    case 'column':
      const {
        title: columnTitle,
      } = componentConfig;

      return (
        <Column
          elementKey={elementKey}
          id={elementId}
          title={columnTitle}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
          checkChildrenExists={checkChildrenExists}
        >
          {childrenComponents}
        </Column>
      );

    case 'grid':
      const {
        gridColumnsSplit,
      } = componentConfig;
      return (
        <Grid
          gridColumnsSplit={gridColumnsSplit}
          elementKey={elementKey}
          id={elementId}
          componentStyles={styles}
        >
          {childrenComponents}
        </Grid>
      );

    case 'accordion':
      const {
        title: accordionTitle,
        contentStyles,
        maxContentHeight,
      } = componentConfig;
      return (
        <Accordion
          id={elementId}
          componentStyles={styles}
          contentStyles={contentStyles}
          title={accordionTitle}
          maxContentHeight={maxContentHeight}
          gridRow={gridRow}
          gridCol={gridCol}
          checkChildrenExists={checkChildrenExists}
        >
          {childrenComponents}
        </Accordion>
      );

    case 'table':
      const {
        tableDataConfig,
        title,
      } = componentConfig;

      return (
        <Table
          elementKey={elementKey}
          id={elementId}
          tableDataConfig={tableDataConfig}
          title={title}
          dataToUse={dataToUse}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
          checkChildrenExists={checkChildrenExists}
        />
      );

    case 'media':
      const {
        label: mediaLabel,
        mediaPath,
        persistMedia,
        hideLabel,
        useMediaFromBucket,
        bucketMediaKey,
      } = componentConfig;

      return (
        <Media
          elementKey={elementKey}
          id={elementId}
          mediaLabel={mediaLabel}
          mediaPath={mediaPath}
          dataToUse={dataToUse}
          persistMedia={persistMedia}
          componentStyles={styles}
          hideLabel={hideLabel}
          gridRow={gridRow}
          gridCol={gridCol}
          fileUrls={fileUrls}
          useMediaFromBucket={useMediaFromBucket}
          bucketMediaKey={bucketMediaKey}
          checkChildrenExists={checkChildrenExists}
        />
      );

    case 'video':
      const {
        label: videoLabel,
        videoPath,
      } = componentConfig;

      return (
        <VideoPlayer
          elementKey={elementKey}
          id={elementId}
          videoLabel={videoLabel}
          videoPath={videoPath}
          dataToUse={dataToUse}
          componentStyles={styles}
          gridRow={gridRow}
          gridCol={gridCol}
          checkChildrenExists={checkChildrenExists}
        />
      );

    default:
      return null;
  }
};

export const recursivelyIterateAndClearEmptyNodes = (nodeId) => {
  if (!nodeId) return;

  const element = document.getElementById(nodeId);

  element?.children?.forEach((child) => {
    const childId = child?.id;
    recursivelyIterateAndClearEmptyNodes(childId);
  });

  if (element?.children?.length === 0) {
    element?.parentNode?.removeChild(element);
  }
};

GenericComponent.defaultProps = {
  fileUrls: [],
};

GenericComponent.propTypes = {
  componentConfig: PropTypes.object.isRequired,
  responseData: PropTypes.object.isRequired,
  requestData: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  elementKey: PropTypes.string.isRequired,
  additionalMetaData: PropTypes.object.isRequired,
  fileUrls: PropTypes.array,
};

export default GenericComponent;
