/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TiffViewer from './TiffViewer';

function TiffZoom({
  id, className, src, height, onClick,
}) {
  const [zoom, setZoom] = useState(false);
  const [transformOrigin, setTransformOrigin] = useState('');
  const imageContainerRef = useRef(null);

  const handleMouseOver = () => {
    setZoom(true);
  };

  const handleMouseOut = () => {
    setZoom(false);
  };

  const handleMouseMovement = (e) => {
    const {
      left: offsetLeft,
      top: offsetTop,
    } = imageContainerRef.current.getBoundingClientRect();
    const mouseX = (e.pageX - offsetLeft);
    const mouseY = (e.pageY - offsetTop);
    setTransformOrigin(`${mouseX}px ${mouseY}px`);
  };
  return (
    <div
      data-hj-suppress
      id={id}
      className={`${className} common_zoom__container`}
      style={{
        height,
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={imageContainerRef}
      onClick={onClick}
      aria-hidden
    >
      <TiffViewer src={src} zoom={zoom} transformOrigin={transformOrigin} />
    </div>
  );
}

TiffZoom.defaultProps = {
  id: '',
  className: '',
  height: null,
  onClick: () => {},
};

TiffZoom.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  height: PropTypes.number,
  onClick: PropTypes.func,
};

export default TiffZoom;
