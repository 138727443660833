import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import Fail from '../../../assests/icons/fail.svg';
import ordinalSuffix from '../../../utils/helpers';
import Pass from '../../../assests/icons/pass.svg';
import { getRecordsCounts, getToolTipTextForMissingAttempts } from '../../Record/RecordDetails/Details/utils';
import missingIcon from '../../../assests/icons/missing.svg';
import storeAnalyticsMetadata from '../../../utils/rudderstackMetadata';
import rudderstackEvents, { tableNames } from '../../../constants/rudderstackEventNames';
import redirectArrow from '../../../assests/icons/redirectArrow.svg';
import { updateCurrentTab, updateSelectedModuleToScroll } from '../../../reducers/appDetails';
import { RECORD_PAGE_TABS } from '../../../constants/record';

function CustomTabs({
  id, className, tabIndex, setTabIndex, tabArray, tabIdPrefix, ariaControlsPrefix,
}) {
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const [tabsToDisplay, setTabsToDisplay] = useState(tabArray);
  const [showOnlyLatest, setShowOnlyLatest] = useState(false);

  const {
    total,
    missing,
    success,
  } = getRecordsCounts(tabArray);

  const currentAppId = useSelector((state) => state?.appDetails?.currentCredential?.appId) || '';
  const transactionId = useSelector((state) => state?.appDetails?.transactionData?.transactionId) || '';
  const currentTabToView = useSelector((state) => state?.appDetails?.currentTab) || '';
  const user = useSelector((state) => state?.user) || {};
  const { email: userEmail, clientId: userClientId } = user;

  const handleMissingTabClick = () => {
    const eventName = rudderstackEvents.DASHBOARD_RECORD_MISSING_RECORDS_CLICK;
    const { moduleId = '', originalUrl = '' } = tabArray.find((tab) => tab.moduleId || tab.originalUrl) || {};
    const table = tableNames.AUDIT_PORTAL_PERFORMANCE_MONITORING;
    const eventData = {
      userEmail,
      userClientId,
      missing,
      total,
      appId: currentAppId,
      moduleId,
      transactionId,
      originalUrl,
      timestamp: new Date().toISOString(),
    };
    storeAnalyticsMetadata(eventData, table, eventName);
  };

  const latestTab = tabArray.length > 0 ? [tabArray[0]] : [];

  useEffect(() => {
    if (showOnlyLatest && !isEmpty(tabArray)) {
      setTabsToDisplay([tabArray[0]]);
      return;
    }
    setTabsToDisplay(tabArray);
  }, [tabArray, showOnlyLatest]);

  useEffect(() => {
    if (!isEmpty(currentTabToView) && currentTabToView === RECORD_PAGE_TABS.OVERVIEW) {
      setShowOnlyLatest(true);
      return;
    }
    setShowOnlyLatest(false);
  }, [currentTabToView]);

  const handleRedirectToAllModules = (event) => {
    event.stopPropagation();
    dispatch(updateCurrentTab(RECORD_PAGE_TABS.ALL_MODULES));
    dispatch(updateSelectedModuleToScroll(latestTab.length > 0 ? latestTab[0].moduleId : ''));
  };

  return (
    <Tabs
      id={id}
      className={`${className} custom_tabs`}
      value={tabIndex}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={false}
      aria-label="basic tabs example"
    >
      {tabsToDisplay.map((item, index) => (
        !item.isMissing && (
          <Tab
            key={item.id}
            label={`${ordinalSuffix(tabArray.length - index)} attempt ${index === 0 ? '(latest)' : ''}`}
            id={`${tabIdPrefix}-${index}`}
            icon={item.isSuccess ? <img src={Pass} alt="tick" className="details__status_icon" /> : <img src={Fail} alt="cross" className="details__status_icon" />}
            iconPosition="start"
            aria-controls={`${ariaControlsPrefix}-${index}`}
            className={`${tabIndex === index ? 'tabActive' : ''}`}
          />
        )
      ))}
      {showOnlyLatest && (
        <Tab
          key="data"
          label="View all attempts"
          id={tabIdPrefix}
          icon={<img src={redirectArrow} alt="tick" className="details__status_icon_redirect" />}
          iconPosition="end"
          onClick={handleRedirectToAllModules}
        />
      )}
      {!showOnlyLatest && missing > 0 && (
        <Tab
          label={(
            <Tooltip title={getToolTipTextForMissingAttempts(total, success)} placement="top" theme="dark">
              <img src={missingIcon} alt="cross" className="details__missing_icon" />
            </Tooltip>
          )}
          id={`${tabIdPrefix}-${tabArray.length}`}
          iconPosition="start"
          aria-controls={`${ariaControlsPrefix}-${tabArray.length}`}
          className={`${tabIndex === tabArray.length ? 'tabActive' : ''}`}
          onClick={handleMissingTabClick}
        />
      )}
    </Tabs>
  );
}

CustomTabs.defaultProps = {
  id: null,
  className: null,
  tabIdPrefix: 'simple-tab',
  ariaControlsPrefix: 'simple-tabpanel',
};

CustomTabs.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
  tabArray: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  tabIdPrefix: PropTypes.string,
  ariaControlsPrefix: PropTypes.string,
};

export default CustomTabs;
