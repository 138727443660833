import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, capitalize, get } from 'lodash';

import PropTypes from 'prop-types';
import { ToolTip } from 'clm-components';
import sdkErrorIcon from '../../../../assests/icons/sdkErrorIcon.svg';
import getIconForSubType from '../../LeftPanel/utils';
import { updateIsReasonBehindStatusCollapsibleOpened } from '../../../../reducers/appDetails';
import { END_STATUSES } from '../../../../constants/record';

function ReasonBehindTheStatus({
  failureReason,
  reasonBehindTheStatusModules,
  handleModuleChange,
}) {
  const dispatch = useDispatch();
  const moduleData = useSelector((state) => state.appDetails.moduleData);
  const transactionData = useSelector((state) => get(state, 'appDetails.transactionData', {}));
  const { status } = transactionData;
  const [activeModule, setActiveModule] = useState(null);

  const onModuleClick = (moduleId) => {
    dispatch(updateIsReasonBehindStatusCollapsibleOpened(true));
    setActiveModule(moduleId);
    handleModuleChange(moduleId);
  };

  const getHeaderText = () => {
    if (status === END_STATUSES.USER_CANCELLED) {
      return 'USER DROPPED-OFF AT';
    }
    return 'RESULTED FROM';
  };

  return (
    <div id="right_panel__review_card__reasonBehindTheStatus_container">
      <h4>Reason Behind The Status</h4>
      <div id="right_panel__review_card__reasonBehindTheStatus__text_container">
        <img src={sdkErrorIcon} alt="message" />
        {capitalize(failureReason)}
      </div>
      <div id="right_panel__review_card__reasonBehindTheStatus__modules_container">
        <span id="right_panel__review_card__reasonBehindTheStatus__modules_container__title">{getHeaderText()}</span>
        {!isEmpty(moduleData) && !isEmpty(reasonBehindTheStatusModules) ? (
          Object.entries(moduleData).map(
            ([moduleId, { moduleName, isSuccess, subType }]) => (
              reasonBehindTheStatusModules.includes(moduleId) && (
              <ToolTip title={moduleName} key={moduleId} placement="right">
                <div
                  key={moduleId}
                  id="right_panel__review_card__reasonBehindTheStatus__modules_container__item"
                  className={activeModule === moduleId ? 'active' : ''}
                  onMouseDown={() => onModuleClick(moduleId)}
                  onMouseUp={() => setActiveModule(null)}
                  onMouseLeave={() => setActiveModule(null)}
                  aria-hidden
                >
                  {React.cloneElement(getIconForSubType(subType))}
                  <span>{moduleName}</span>
                  {!isSuccess && (
                  <div id="right_panel__review_card__reasonBehindTheStatus__modules_container__fail" />
                  )}
                </div>
              </ToolTip>
              )
            ),
          )
        ) : (
          <span id="right_panel__review_card__reasonBehindTheStatus__modules_container__no_module">No Issues faced by the user</span>
        )}
      </div>
    </div>
  );
}

ReasonBehindTheStatus.defaultProps = {
  reasonBehindTheStatusModules: [],
};

ReasonBehindTheStatus.propTypes = {
  failureReason: PropTypes.string.isRequired,
  reasonBehindTheStatusModules: PropTypes.array,
  handleModuleChange: PropTypes.func.isRequired,
};

export default ReasonBehindTheStatus;
