// Use requestAnimationFrame later for performance
// Implement ease-in-out if needed
export const scrollElementIntoViewById = async (parentId, elementId) => {
  const parentElement = document.getElementById(parentId);
  const element = document.getElementById(elementId);
  if (!parentElement || !element) return;

  const TOP_OFFSET = 5; // Add constant for top spacing
  await new Promise((resolve) => setTimeout(resolve, 50));

  const parentCurrentScrollTop = parentElement.scrollTop;
  const scrollAmount = element.offsetTop
    - TOP_OFFSET // Subtract offset from target position
    - parentCurrentScrollTop
    - parseFloat(
      window
        .getComputedStyle(parentElement, null)
        .getPropertyValue('padding-top'),
    );

  const scrollTime = 250;
  const scrollAmountPerMs = scrollAmount / 100;

  let curTime = 0;
  let scrollCounter = 0;

  while (curTime <= scrollTime) {
    const newScrollTop = parentCurrentScrollTop + scrollAmountPerMs * scrollCounter;
    window.setTimeout(() => {
      parentElement.scrollTop = newScrollTop;
    }, curTime);
    curTime += scrollTime / 100;
    scrollCounter += 1;
  }

  await new Promise((resolve) => setTimeout(resolve, scrollTime));
};

export const smoothHorizontalScrolling = (
  e,
  scrollTime,
  scrollAmount,
  currentScrollLeft,
  direction,
) => {
  const scrollAmountPerMs = scrollAmount / 100;

  let curTime = 0;
  let scrollCounter = 0;
  while (curTime <= scrollTime) {
    let newScrollLeft;
    if (direction === 'left') {
      newScrollLeft = currentScrollLeft - scrollAmountPerMs * scrollCounter;
    } else {
      newScrollLeft = currentScrollLeft + scrollAmountPerMs * scrollCounter;
    }
    window.setTimeout(() => {
      e.scrollLeft = newScrollLeft;
    }, curTime);
    curTime += scrollTime / 100;
    scrollCounter += 1;
  }
};

export const test = '';
