/* eslint-disable jsx-a11y/control-has-associated-label */
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { set, get, isEmpty } from 'lodash';
import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import DataTable from './DataTable';
import AlertCode from '../../../constants/alertCodes';
import useShowErrorAlert, { useFormatAnalyticsData, useShowToastNotification, useFormatPerformanceAnalyticsData } from '../../../utils/lib';
import {
  updateData, updateTotalElements, updatePage,
  updateReviewersList,
  updateDashboardDataTableColumns,
  updateSelectHeadStateForApplicationsTable,
  updateSelectedTransactions,
  removeSelectedTransaction,
  clearAllSelectedTransactions,
  updateAssignedTransactionCountData,
  updatePreviousAssignedList,
  updateColumns,
} from '../../../reducers/data';
import { PERFORMANCE_METRIC_EVENTS, constants } from '../../../config';
import './Audit.scss';
import CommonSearchBar from '../../Common/Inputs/CommonSearchBar';
import ToastNotification from '../../Common/ToastNotification';
import AddFilter from '../../Common/Filter/AddFilter';
import EditFilter from '../../Common/Filter/EditFilter';
import RefreshIcon from '../../../assests/icons/refresh.png';
import { fetchFilterList, getFiltersFromLocalStorage, getTransactionMetadataFilters } from './AuditFilterUtils';
import TableActions from './TableActions/TableActions';
import TableActionsButton from './TableActions/TableActionsButton';
import CustomPopupContainer from '../../Common/Popup/CustomPopupContainer';
import useS3Config from '../../../utils/hooks/s3Config';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';
import { applicationStatusLabelMap } from '../../../constants/applicationFilters';
import { isNonEmptyObject, isNumber, safeSetValueToLS } from '../../../utils/helpers';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import { closeToastNotification } from '../../../reducers/alert';
import screenNames from '../../../constants/screenNames';
import errorCode from '../../../constants/errorCode';
import getReviewerListApi from '../../../api/iam';
import { updateReviewerApi, getTransactionCountApi } from '../../../api/audit';
import ManualAssignmentPopup from '../../ManualAssignment/Application/popups/ManualAssignmentPopup';
import ManualAssignmentToolBar from '../../ManualAssignment/Application/popups/ManualAssignmentToolBar';
import Modal from '../../ManualAssignment/common/Modal/Modal';
import ModalBody from '../../ManualAssignment/Application/ModalBody';
import SuccessIcon from '../../../assests/icons/success.svg';

axios.defaults.withCredentials = true;

function Audit() {
  const loadingRef = useRef(false);
  const queuedCallsRef = useRef([]);
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();
  const showNotification = useShowToastNotification();
  const auditData = useSelector((state) => state.data.value);
  const status = useSelector((state) => state.data.status);
  const metaData = useSelector((state) => state.data.metaData);
  const sortBy = useSelector((state) => state.data.sortBy);
  const transactionMetadataFilters = useSelector(
    (state) => state?.data?.transactionMetadataFilters,
  );
  const valueMetaData = {};
  Object.keys(metaData).forEach((key) => {
    valueMetaData[key] = metaData[key].value;
  });
  const application = useSelector((state) => state.data.application);
  const { startTime, endTime } = application.value;
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const totalElements = useSelector((state) => state.data.totalElements);
  const page = useSelector((state) => state.data.page);
  const workflowList = useSelector((state) => state.user.workflowIdListWithDbData);
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const selectHeadState = useSelector((state) => state.data.selectHeadStateForApplicationsTable);
  const [prevAppId, setPrevAppId] = useState(null);
  const [loading, setLoading] = useState(false);
  const transactionIdInLS = localStorage.getItem('application_table_filters_transactionId') || '';
  const [searchKey, setSearchKey] = useState(transactionIdInLS);
  const location = useLocation();

  const [selectedFilterList, setSelectedFilterList] = useState([]);
  const [selectedFilterDetailedList, setSelectedFilterDetailedList] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(true);
  const [availableFilterList, setAvailableFilterList] = useState([]);
  const [availableFilterListlength, setAvailableFilterListLength] = useState(0);
  const selectedTableColumns = useSelector((state) => state.data.selectedColumns);
  const landingPage = location.state?.page || 0;
  const searchInit = location.state?.searchId || transactionIdInLS || '';
  const getFilterConfig = useS3Config('filterConfig');
  const getTransactionMetadataColumnsConfig = useS3Config('transactionMetadataColumnsConfig');
  const formatAnalyticsData = useFormatAnalyticsData();
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const [toastIcon, setToastIcon] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const isSelectAll = useSelector((state) => get(state, 'data.selectHeadStateForApplicationsTable.headState.all', false));
  const selectedTransactionsForManualAssignment = Object.keys(useSelector((state) => get(state, 'data.selectedTransactionsForManualAssignment', {})));
  const transactionCountData = useSelector((state) => get(state, 'data.assignedTransactionCountData', {}));
  const applicationManualReviewAssignment = useSelector((state) => get(state, 'config.applicationManualReviewAssignment', 'none'));

  useEffect(() => {
    if (selectedFilterDetailedList.length > 0) {
      const stringifiedSelectedFilterList = JSON.stringify(selectedFilterDetailedList);
      safeSetValueToLS('application_table_filter_detailed_list', stringifiedSelectedFilterList);
    }
  }, [selectedFilterDetailedList]);

  useEffect(() => {
    if (selectedFilterList.length > 0) {
      const stringifiedSelectedFilterList = JSON.stringify(selectedFilterList);
      safeSetValueToLS('application_table_filter_list', stringifiedSelectedFilterList);
    }
  }, [selectedFilterList]);

  useEffect(() => {
    if (applicationManualReviewAssignment !== 'none' && !selectedTableColumns.find((column) => column.id === 'reviewerEmail')) {
      const updatedColumns = [
        ...selectedTableColumns,
        { id: 'reviewerEmail', value: 'reviewerEmail', label: 'Assignee' },
      ];
      dispatch(updateColumns(updatedColumns));
    } else {
      const updatedColumns = selectedTableColumns.filter((column) => column.id !== 'reviewerEmail');
      dispatch(updateColumns(updatedColumns));
    }
  }, [applicationManualReviewAssignment]);

  const renderNotification = () => (
    <ToastNotification
      imageComponentRenderer={() => (<img className="actions_icon" src={toastIcon} alt="" />)}
      message={toastMessage}
    />
  );

  const getCommonFilters = () => {
    const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
      transactionMetadataFilters,
    );
    return {
      searchValue: searchKey,
      status: status.value,
      startTime,
      endTime,
      transactionMetadataFilters: formattedTransactionMetadataFilters,
      ...valueMetaData,
    };
  };

  const fetchTableData = async () => {
    const APIStartedTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/audit/filter',
      metaData: {
        appId: currentAppId,
        filters: {
          startDate: startTime,
          endDate: endTime,
          searchValue: searchKey,
          status: status?.value,
          workflowId: valueMetaData?.workflowId?.values,
        },
      },
    };
    try {
      const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
        transactionMetadataFilters,
      );
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/filter`,
        headers: { appId: currentAppId },
        data: {
          searchValue: searchKey,
          status: status.value,
          startTime,
          endTime,
          page,
          rows: constants.maxRecordsInTable,
          transactionMetadataFilters: formattedTransactionMetadataFilters,
          ...valueMetaData,
          ...(isNonEmptyObject(sortBy) ? { sortBy } : {}),
        },
      });
      set(eventObj, 'statusCode', dataRes.status);
      dispatch(updateData(dataRes.data.result.data));
      dispatch(updateDashboardDataTableColumns(
        get(dataRes, 'data.result.dashboardDataConfig.applicationListTableColumns', []),
      ));
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      Sentry.captureException(`${errorCode.FETCH_APPLICATION_ERROR} - ${error}`, {
        extra: {
          errorMessage: 'Error Fetching Table Data',
        },
      });
      dispatch(updateData([]));
      dispatch(updateTotalElements(0));
      showErrorAlert({ error, message: AlertCode.FETCH_TABLE_DATA });
    }
    const processingTime = performance.now() - APIStartedTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_FETCH_TABLE_DATA;
    set(eventObj, 'processingTime', processingTime);
    formatPerformanceAnalyticsData(eventObj, eventName);
    setLoading(false);
  };
  const onSelectAssignee = (assignee) => {
    setSelectedAssignee(assignee);
  };

  const fetchRowCount = async () => {
    const APIStartedTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/audit/count',
      metaData: {
        appId: currentAppId,
        filters: {
          startDate: startTime,
          endDate: endTime,
          searchValue: searchKey,
          status: status?.value,
          workflowId: valueMetaData?.workflowId?.values,
        },
      },
    };
    try {
      const formattedTransactionMetadataFilters = getTransactionMetadataFilters(
        transactionMetadataFilters,
      );
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/count`,
        headers: { appId: currentAppId },
        data: {
          searchValue: searchKey,
          status: status.value,
          startTime,
          endTime,
          transactionMetadataFilters: formattedTransactionMetadataFilters,
          ...valueMetaData,
        },
      });
      if (res.data.result.count === 0 && prevAppId !== currentAppId) {
        setPrevAppId(currentAppId);
      }
      set(eventObj, 'statusCode', res.status);
      dispatch(updateTotalElements(res.data.result.count));
    } catch (error) {
      Sentry.captureException(`${errorCode.APPLICATION_FETCH_COUNT_ERROR} - ${error}`, {
        extra: {
          errorMessage: 'Error Fetching Row Count',
        },
      });
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      showErrorAlert({ error, message: AlertCode.FETCH_ROW_COUNT });
    }
    const processingTime = performance.now() - APIStartedTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_FETCH_ROW_COUNT;
    set(eventObj, 'processingTime', processingTime);
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  const fetchReviewerList = async () => {
    if (currentAppId && currentAppId !== '') {
      try {
        const {
          reviewersList,
          previouslyAssignedReviewersList: prevAssignedList,
        } = await getReviewerListApi({
          appId: currentAppId,
          ...getCommonFilters(),
        });
        dispatch(updatePreviousAssignedList(prevAssignedList));
        reviewersList.unshift({ emails: [{ id: 'Unassigned' }] });
        dispatch(updateReviewersList({ reviewersList }));
      } catch (err) {
        dispatch(updateReviewersList({}));
      }
    }
  };

  const fetchAssignedAndUnAssignedTransactionCount = async () => {
    try {
      const payload = {
        appId: currentAppId,
        ...getCommonFilters(),
        status: 'needs_review',
      };
      const res = await getTransactionCountApi(payload);
      const totalCount = get(res, 'data.result.totalCount', 0);
      const unAssignedCount = get(res, 'data.result.unassignedCount', 0);
      dispatch(updateAssignedTransactionCountData({
        total: totalCount,
        unAssigned: unAssignedCount,
      }));
    } catch (error) {
      dispatch(updateAssignedTransactionCountData({
        total: 0,
        unAssigned: 0,
      }));
    }
  };

  const handleQueuedCalls = () => {
    if (!isEmpty(queuedCallsRef.current)) {
      const nextCall = queuedCallsRef.current.shift();
      loadingRef.current = false;
      nextCall();
    } else {
      loadingRef.current = false;
      setLoading(false);
    }
  };
  const handleDataChange = async () => {
    dispatch(updatePage(landingPage));
    location.state = undefined;
    if (loadingRef.current) {
      queuedCallsRef.current.push(handleDataChange);
      return;
    }

    loadingRef.current = true;
    setLoading(true);

    try {
      await Promise.all([
        fetchTableData(),
        fetchRowCount(),
        fetchReviewerList(),
        fetchAssignedAndUnAssignedTransactionCount(),
      ]);
      handleQueuedCalls();
    } catch (error) {
      handleQueuedCalls();
    }
  };

  useEffect(() => {
    if (currentAppId && currentAppId !== '') handleDataChange();
  }, [
    status,
    startTime,
    endTime,
    currentAppId,
    metaData,
    searchKey,
    sortBy,
    transactionMetadataFilters,
  ]);

  const handlePageChange = async (event, newPage) => {
    const pageChangeStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/audit/filter',
      metaData: {
        appId: currentAppId,
        filters: {
          startDate: startTime,
          endDate: endTime,
          searchValue: searchKey,
          status: status?.value,
          workflowId: valueMetaData?.workflowId?.values,
          page: newPage,
        },
      },
    };
    setLoading(true);
    try {
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit/filter`,
        headers: { appId: currentAppId },
        data: {
          ...getCommonFilters(),
          page: newPage - 1,
          rows: constants.maxRecordsInTable,
          ...(isNonEmptyObject(sortBy) ? { sortBy } : {}),
        },
      });
      set(eventObj, 'statusCode', dataRes.status);
      dispatch(updatePage(newPage - 1));
      dispatch(updateData(dataRes.data.result.data));
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      Sentry.captureException(`${errorCode.FETCH_TABLE_DATA} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_TABLE_DATA,
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_TABLE_DATA });
    }
    const processingTime = performance.now() - pageChangeStartTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_CHANGE;
    set(eventObj, 'processingTime', processingTime);
    formatPerformanceAnalyticsData(eventObj, eventName);
    setLoading(false);
  };

  const handleRowClick = (row) => {
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBOARD_APPLICATIONS_APPLICATION_CLICK,
      screenNames.APPLICATIONS,
    );
    window.open(`/record?transactionId=${encodeURIComponent(row.transactionId.data.id)}&currentElement=${row.transactionId.data.rowId}&searchValue=${searchKey}`);
  };

  const onFilterAdd = (details) => {
    const APIStartedTime = performance.now();
    const {
      key, updateFuntion, label, selectedOptions,
    } = details;
    const { filterLabel, value } = details.genLabelFunc(details);
    setSelectedFilterList([...selectedFilterList, key]);
    setSelectedFilterDetailedList((oldList) => [
      ...oldList,
      {
        details,
        filterLabel,
      },
    ]);
    dispatch(updatePage(0));
    dispatch(updateFuntion(
      {
        data: { key, value },
        filterData: details,
      },
    ));
    const processingTime = performance.now() - APIStartedTime;
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBAORD_APPLICATIONS_FILTER_CHANGE,
      screenNames.APPLICATIONS,
    );
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_FILTER_CHANGE;
    const eventObj = {
      processingTime,
      metaData: {
        appId: currentAppId,
        filters: {
          startDate: startTime,
          endDate: endTime,
          searchValue: searchKey,
          status: status?.value,
          workflowId: valueMetaData?.workflowId?.values,
          label,
          selectedOptions,
        },
      },
    };
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  const onFilterEdit = (details, idxToEdit) => {
    const { key, updateFuntion } = details;
    const { filterLabel, value } = details.genLabelFunc(details);
    setSelectedFilterDetailedList((oldList) => oldList.map((filter, idx) => (idx !== idxToEdit
      ? filter
      : {
        details,
        filterLabel,
      })));
    dispatch(updatePage(0));
    dispatch(updateFuntion(
      {
        data: { key, value },
        filterData: details,
      },
    ));
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBAORD_APPLICATIONS_FILTER_CHANGE,
      screenNames.APPLICATIONS,
    );
  };

  const removeFilter = (details, idxToRemove) => {
    const { key: keyToRemove, updateFuntion } = details;
    setSelectedFilterList((oldList) => oldList.filter((key) => key !== keyToRemove));
    setSelectedFilterDetailedList((oldList) => oldList.filter(
      (filter, idx) => idx !== idxToRemove,
    ));
    dispatch(updatePage(0));
    dispatch(updateFuntion({ data: { key: keyToRemove } }));
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBAORD_APPLICATIONS_FILTER_CHANGE,
      screenNames.APPLICATIONS,
    );
  };

  const isValid = (data) => {
    if (typeof data === 'string' && data !== '') return true;
    if (typeof data === 'object' && Array.isArray(data) && data.length) return true;
    if (typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length) return true;
    return false;
  };

  const initializeFilters = async () => {
    const filterConfig = await getFilterConfig();
    const initFilterStartTime = performance.now();
    const { filterList, filterListLength } = await fetchFilterList(filterConfig);
    setAvailableFilterList(filterList);
    setAvailableFilterListLength(filterListLength);

    // Get the available filter from local storage and set the filtered list
    const {
      selectedFilterListInLS, selectedDetailedFilterInLS,
    } = getFiltersFromLocalStorage({ filterList, dispatch });
    if (selectedDetailedFilterInLS && !isEmpty(selectedDetailedFilterInLS)) {
      setSelectedFilterList(selectedFilterListInLS);
      setSelectedFilterDetailedList(selectedDetailedFilterInLS);
      // returning here since we get the data from LS
      return;
    }

    const initSelectedFilterList = [];
    const initSelectedFilterDetailedList = [];
    const initData = {
      createdAt: { ...application }, ...transactionMetadataFilters, ...metaData, status,
    };
    const initStatus = queryParams.get('status') ? queryParams.get('status').split(',') : [];
    const initWorkflowID = queryParams.get('workflowId');
    const initStartTime = queryParams.get('startDate');
    const initEndTime = queryParams.get('endDate');
    const filterFromQueryParamsToUpdateReduxState = [];

    if (initStatus && initStatus.length) {
      initData.status = {
        ...initData.status,
        value: { values: initStatus, contains: true },
        filterData: {
          contains: true,
          selectedOptions: initStatus.map((initStatusElement) => ({
            value: initStatusElement,
            label: applicationStatusLabelMap[initStatusElement],
          })),
        },
      };
      filterFromQueryParamsToUpdateReduxState.push('status');
    }

    if (initWorkflowID) {
      const workflows = initWorkflowID.split(',');
      const selectedOptions = [];
      const values = [];
      workflows.forEach((workflow) => {
        const ele = workflowList.find((w) => w.id === workflow);
        if (ele) {
          selectedOptions.push({ value: ele.id, label: ele.name || ele.id });
          values.push(ele.id);
        }
      });
      if (selectedOptions.length) {
        initData.workflowId = {
          ...initData.workflowId,
          value: { values, contains: true },
          filterData: { contains: true, selectedOptions },
        };
        filterFromQueryParamsToUpdateReduxState.push('workflowId');
      }
    }

    if (initStartTime && initEndTime) {
      const filterTimeRange = {
        endTime: new Date(+initEndTime).toISOString(),
        startTime: new Date(+initStartTime).toISOString(),
      };
      initData.createdAt = {
        filterData: { selectedDateRange: filterTimeRange },
        value: filterTimeRange,
      };
      filterFromQueryParamsToUpdateReduxState.push('createdAt');
    }

    Object.entries(initData).forEach(([key, { filterData, value }]) => {
      if (isValid(value) && filterList) {
        filterList.some((group) => {
          const filter = group.filters.find((filterItem) => filterItem.key === key);
          if (filter && filterData && isValid(filterData)) {
            const details = { ...filter, ...filterData, value };
            const { filterLabel, value: filterValue } = details.genLabelFunc(details);
            if (filterFromQueryParamsToUpdateReduxState.includes(details.key)) {
              dispatch(details.updateFuntion({
                data: { key: details.key, value: filterValue },
                filterData: details,
              }));
            }
            initSelectedFilterList.push(key);
            initSelectedFilterDetailedList.push({
              details,
              filterLabel,
            });
            return true;
          }
          return false;
        });
      }
    });
    const processingTime = performance.now() - initFilterStartTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_FETCH_FILTER_CONFIG;
    const eventObj = {
      processingTime,
      metaData: {
        workflowId: initWorkflowID,
      },
    };
    formatPerformanceAnalyticsData(eventObj, eventName);
    setSelectedFilterList(initSelectedFilterList);
    setSelectedFilterDetailedList(initSelectedFilterDetailedList);
  };

  const initializeTransactionMetadataColumnsConfigs = async () => {
    const transactionMetaDataConfigStartTime = performance.now();
    await getTransactionMetadataColumnsConfig();
    const processingTime = performance.now() - transactionMetaDataConfigStartTime;
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_FETCH_TRANSACTION_METADATA_CONFIG;
    const eventObj = {
      processingTime,
    };
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  const handleTransactionSearch = (value) => {
    safeSetValueToLS('application_table_filters_transactionId', value);
    setSearchKey(value);
    formatAnalyticsData(
      email,
      clientId,
      rudderstackEvents.DASHBOARD_APPLICATIONS_SEARCH,
      screenNames.APPLICATIONS,
    );
  };

  const handleSelectHeadStateChange = (key) => {
    const headState = {
      all: false,
      pageAll: false,
      none: false,
    };
    const pageState = {
      pageNo: 0,
      pageSize: constants.maxRecordsInTable,
    };
    if (key === 'page-all') {
      headState.pageAll = true;
      pageState.pageNo = page;
      dispatch(updateSelectHeadStateForApplicationsTable({ headState, pageState }));
      return;
    }
    headState[key] = true;
    dispatch(updateSelectHeadStateForApplicationsTable({ headState, pageState }));
  };

  const handleRowCheckBoxChange = (e, row) => {
    if (e.target.checked) {
      dispatch(updateSelectedTransactions(row.selectOptions.data));
    } else {
      dispatch(removeSelectedTransaction(row.selectOptions.data));
    }
  };

  const setSelectHeadToNone = () => {
    const obj = {
      all: false,
      pageAll: false,
      none: true,
    };
    dispatch(updateSelectHeadStateForApplicationsTable({
      headState: obj,
      pageState: { pageNo: 0, pageSize: constants.maxRecordsInTable },
    }));
    showErrorAlert({ message: 'No applications to select' });
  };

  useEffect(() => {
    switch (true) {
      case selectHeadState.headState.all:
        if (transactionCountData.total === 0 || Number(transactionCountData.total) === 0) {
          setSelectHeadToNone();
        }
        break;
      case selectHeadState.headState.pageAll:
        if (page === selectHeadState.pageState.pageNo) {
          const selectedTransactionsList = auditData
            .filter((data) => data.status === 'needs_review')
            .map((data) => data.transactionId);
          if (selectedTransactionsList.length === 0) {
            setSelectHeadToNone();
          }
          dispatch(updateSelectedTransactions(selectedTransactionsList));
        }
        break;
      case selectHeadState.headState.none:
        setSelectedAssignee('');
        dispatch(clearAllSelectedTransactions());
        break;
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditData, dispatch, page, selectHeadState]);

  useEffect(() => () => {
    dispatch(closeToastNotification());
  }, []);

  const handleDeSelectAllTransactions = () => {
    const obj = {
      all: false,
      pageAll: false,
      none: true,
    };
    setSelectedAssignee('');
    dispatch(updateSelectHeadStateForApplicationsTable({
      headState: obj,
      pageState: { pageNo: 0, pageSize: constants.maxRecordsInTable },
    }));
    dispatch(clearAllSelectedTransactions());
  };

  useEffect(() => {
    initializeFilters();
    initializeTransactionMetadataColumnsConfigs();
  }, []);

  useEffect(() => {
    if (selectedFilterList.length > 1) setIsSearchOpen(false);
    else setIsSearchOpen(true);
  }, [selectedFilterList]);

  const onAssigneeChange = async () => {
    try {
      const assigneeToBeAssigned = selectedAssignee;
      const selectedTransactions = selectedTransactionsForManualAssignment;
      setSelectedAssignee('');
      dispatch(clearAllSelectedTransactions());
      handleDeSelectAllTransactions();

      setLoading(true);
      if (isSelectAll) clearAllSelectedTransactions();
      await Promise.all([
        updateReviewerApi({
          transactionIds: isSelectAll ? [] : selectedTransactions,
          selectedReviewerEmail: assigneeToBeAssigned,
          appId: currentAppId,
          updateAll: isSelectAll ? 'yes' : 'no',
          ...getCommonFilters(),
        }),
        fetchReviewerList(),
        fetchAssignedAndUnAssignedTransactionCount(),
      ]);
      await handleDataChange();
      setToastIcon(SuccessIcon);
      const count = isSelectAll ? transactionCountData.total
        : selectedTransactionsForManualAssignment.length;
      const message = (
        <div className="selcted_assignee_message_container">
          <span>
            Successfully assigned
            {' '}
            {count}
            {' '}
            Selected Applications to
            {' '}
            <strong>{selectedAssignee}</strong>
          </span>
        </div>
      );
      setToastMessage(message);
      showNotification();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorAlert({ error, message: 'Error occurred when updating Assignee' });
      setSelectedAssignee('');
    }
  };

  return (
    <>
      <div id="audit__data_container">
        <div id="audit__header_container">
          <div id="audit__filter_container">
            <CommonSearchBar
              placeholder="Search For Name or Transaction ID"
              handleSearch={handleTransactionSearch}
              isInputVisible={isSearchOpen}
              initValue={searchInit}
              isDebounce
            />
            {selectedFilterDetailedList.map(({ details, filterLabel }, idx) => (
              <EditFilter
                key={details.key}
                filterLabel={filterLabel}
                details={details}
                onFilterEdit={(editedDetails) => onFilterEdit(editedDetails, idx)}
                onClose={() => removeFilter(details, idx)}
              />
            ))}
            {availableFilterListlength
            && availableFilterListlength !== selectedFilterList.length ? (
              <AddFilter
                onFilterAdd={onFilterAdd}
                availableFilterList={availableFilterList}
                selectedFilterList={selectedFilterList}
              />
              ) : null}
            <div id="audit__actions_container">
              <p id="audit__element_count">
                {totalElements}
                {' '}
                applications
              </p>
              <button
                type="button"
                id="audit__filter_count_refresh"
                onClick={() => handleDataChange()}
              >
                <img src={RefreshIcon} alt="" aria-hidden />
              </button>

              <CustomPopupContainer
                popupPosition="bottom-left"
                popupTrigger={<TableActionsButton />}
                popupComponent={<TableActions searchValue={searchKey} />}
              />
            </div>
          </div>
        </div>
        <Grid item xs={12}>
          <DataTable
            handlePageChange={handlePageChange}
            handleRowClick={handleRowClick}
            tableColumns={selectedTableColumns}
            isLoading={loading}
            data={auditData}
            page={page}
            totalElements={totalElements}
            handleSelectHeadState={handleSelectHeadStateChange}
            handleRowCheckBoxChange={handleRowCheckBoxChange}
          />
        </Grid>
        {(selectedTransactionsForManualAssignment.length > 0 || isSelectAll)
        && !selectedAssignee ? (
          <ManualAssignmentToolBar
            onDeSelectAll={handleDeSelectAllTransactions}
          >
            <ManualAssignmentPopup
              onSelectAssignee={onSelectAssignee}
              selectedFilterList={selectedAssignee}
              position="top-right"
              onClose={() => setSelectedAssignee('')}
            />
          </ManualAssignmentToolBar>
          ) : null}
        <Modal
          isOpen={selectedAssignee}
          onClose={() => setSelectedAssignee('')}
          onSubmit={onAssigneeChange}
          header="Confirm Application Assignment"
          id="Manual_assigment_modal_applications"
        >
          <ModalBody
            assigneeEmail={selectedAssignee}
          />
        </Modal>
        <div className="toast_container">
          {renderNotification()}
        </div>
      </div>
    </>
  );
}

export default PermissionWrapper(Audit, useGetUserPermissions, getPermission('auditPage'));
