import React, { useState, useEffect } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { get, values, set } from 'lodash';
import * as Sentry from '@sentry/react';
import useShowErrorAlert, { useFormatAnalyticsData, useFormatPerformanceAnalyticsData } from '../../../utils/lib';
import '../Account.scss';
import {
  getClientInfo, getModuleCost, getPlatformCost, getTotalCost,
} from '../../../api/usage';
import {
  initUsage,
  billingDateFormat,
  getMonthYearString,
} from './usageUtils';
import { startDownload } from '../../../reducers/download';
import AlertCode from '../../../constants/alertCodes';
import useS3Config from '../../../utils/hooks/s3Config';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';
import { updateModuleUsage, updateTransactionUsage, updateBillingSummary } from '../../../reducers/pricing';
import ModuleBilling from './ModuleBilling';
import PlatformBilling from './PlatformBilling';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import screenNames from '../../../constants/screenNames';
import { transactionDataDownloadType } from '../../../containers/Common/Download/utils';
import errorCode from '../../../constants/errorCode';
import { PERFORMANCE_METRIC_EVENTS } from '../../../config';
import { isNumber } from '../../../utils/helpers';
import CommonSelect from '../../Common/Inputs/CommonSelect';
import UsageSummary from './UsageSummary';
import BillSummary from './BillSummary';

const {
  previousMonthStartDate,
  dates,
} = initUsage();

function Usage() {
  const dispatch = useDispatch();
  const getCommonConfig = useS3Config('commonConfig');
  const showErrorAlert = useShowErrorAlert();
  const [isActive, setIsActive] = useState('current');
  const [currency, setCurrency] = useState('');
  const [selectedDate, setSelectedDate] = useState(previousMonthStartDate);
  const [isLoading, setIsLoading] = useState(false);
  const userAppIds = useSelector((state) => state.user.appIds);
  const downloadList = useSelector((state) => state.download);
  const clientId = useSelector((state) => state.user.clientId);
  const email = useSelector((state) => state.user.email);
  const { appIdWiseUsageData } = useSelector((state) => state.pricing.moduleUsage);
  const formatAnalyticsData = useFormatAnalyticsData();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const [appIdInFilter, setAppIdInFilter] = useState('all');

  // eslint-disable-next-line max-len
  const getPreviousMonthStartDate = (options) => (options.length >= 2 ? options[1].value : options[0].value);

  const appIdOptions = Object.keys(appIdWiseUsageData)
    ?.map((appIdValue) => ({ label: appIdValue, value: appIdValue }));
  appIdOptions.push({ label: 'All', value: 'all' });

  const loadData = async (date) => {
    const startTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/billing/module, api/v1/billing/platform',
      metaData: {
        selectedDate,
      },
    };
    try {
      setIsLoading(true);
      await getCommonConfig();
      // need to change usage config, needs to same as admin dashboard
      const startDate = date.format(billingDateFormat);
      const endDate = date
        .clone()
        .add('month', 1)
        .subtract('day', 1)
        .format(billingDateFormat);
      const [moduleUsageRes, platformUsageRes, billingSummaryRes] = await Promise.all([
        getModuleCost(startDate, endDate, clientId),
        getPlatformCost(startDate, clientId),
        getTotalCost(startDate, endDate, clientId),
      ]);
      set(eventObj, 'statusCode', 200);
      const buidModuleUsageData = get(moduleUsageRes, 'data.result.buid', {});
      const buidPlatformUsageData = get(platformUsageRes, 'data.result.buid', {});
      const appIdWiseModuleUsageData = get(moduleUsageRes, 'data.result.appId', {});
      const appIdWisePlatformUsageData = get(platformUsageRes, 'data.result.appId', {});
      dispatch(updateModuleUsage({
        data: buidModuleUsageData.total.total.usageRows,
        totalCost: buidModuleUsageData.total.total.totalCost,
        appIdWiseUsageData: appIdWiseModuleUsageData,
      }));
      dispatch(updateTransactionUsage({
        data: buidPlatformUsageData.total.total.usageRows,
        totalCost: buidPlatformUsageData.total.total.totalCost,
        appIdWiseUsageData: appIdWisePlatformUsageData,
      }));
      dispatch(updateBillingSummary(get(billingSummaryRes, 'data.result')));
      const getClientInfoRes = await getClientInfo(clientId);
      const { companyDetails, businessUnits } = get(getClientInfoRes, 'data.result.data', {});
      const { billingCurrency } = companyDetails || {};
      const currencyValue = billingCurrency || get(values(businessUnits), [0, 'billingCurrency'], '');
      setCurrency(currencyValue);
    } catch (err) {
      if (isNumber(err?.response?.statusCode)) set(eventObj, 'statusCode', err?.response?.statusCode);
      Sentry.captureException(`${errorCode.USAGE_FETCH_ERROR} - ${err}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_USAGE_DATA,
        },
      });
      showErrorAlert({ err, message: AlertCode.ERROR_FETCHING_USAGE_DATA });
    }
    const processingTime = performance.now() - startTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.ACCOUNT_PAGE_FETCH_USAGE;
    formatPerformanceAnalyticsData(eventObj, eventName);
    setIsLoading(false);
  };

  const fetchLogs = async (downloadType) => {
    const fetchLogsStartTime = performance.now();
    const eventName = downloadType === transactionDataDownloadType
      ? rudderstackEvents.DASHBOARD_USAGE_FETCH_PLATFORM_LOGS_CLICK
      : rudderstackEvents.DASHBOARD_USAGE_FETCH_MODULE_LOGS_CLICK;
    const APIEndPoint = downloadType === transactionDataDownloadType
      ? 'api/v2/usage/transaction/logs'
      : 'api/v2/usage/logs/';
    formatAnalyticsData(
      email,
      clientId,
      eventName,
      screenNames.USAGE,
    );

    const startDate = selectedDate.format(billingDateFormat);
    let appIdList = Object.keys(userAppIds);
    const eventObj = {
      APIEndPoint,
      metaData: {
        appIdList,
        startDate,
      },
    };
    if (appIdInFilter !== 'all') appIdList = [appIdInFilter];
    if (
      downloadList
      && downloadList.find(({ type }) => type === downloadType)
    ) {
      Sentry.captureException(`${errorCode.USAGE_DUPLICATE_REQUEST} - ${AlertCode.usageLogs.DUPLICATE_REQUEST}`, {
        extra: {
          errorMessage: AlertCode.usageLogs.DUPLICATE_REQUEST,
        },
      });
      showErrorAlert({ message: AlertCode.usageLogs.DUPLICATE_REQUEST });
    } else {
      set(eventObj, 'statusCode', 200);
      dispatch(
        startDownload({
          type: downloadType,
          apiData: { startDate, appIdList },
        }),
      );
    }
    const processingTime = performance.now() - fetchLogsStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventNameForPerformanceMetrics = downloadType === transactionDataDownloadType
      ? PERFORMANCE_METRIC_EVENTS.USAGE_PAGE_FETCH_PLATFORM_LOGS_CLICK
      : PERFORMANCE_METRIC_EVENTS.USAGE_PAGE_FETCH_MODULE_LOGS_CLICK;
    formatPerformanceAnalyticsData(eventObj, eventNameForPerformanceMetrics);
  };

  const buttonClick = (type, date) => {
    formatAnalyticsData(
      email, clientId, rudderstackEvents.DASHBOARD_USAGE_MONTH_CHANGE, screenNames.USAGE,
    );
    setIsActive(type);
    setSelectedDate(date);
  };

  const handleAppIdChange = (value) => {
    setAppIdInFilter(value);
  };

  useEffect(() => {
    loadData(selectedDate);
  }, [selectedDate]);

  return (
    <div id="usage__container">
      <div id="usage__header">
        <h1>Billing & Usage: </h1>
        <CommonSelect
          options={dates}
          label="Choose a Month"
          isSelected={isActive === 'menu'}
          handleChange={(value) => {
            buttonClick('menu', value);
          }}
          initValue={getPreviousMonthStartDate(dates)}
          disabled={isLoading}
          isText
        />
      </div>
      <BillSummary currency={currency} />
      <UsageSummary
        appIdOptions={appIdOptions}
        isLoading={isLoading}
        selectedDate={getMonthYearString(selectedDate)}
        handleAppIdChange={handleAppIdChange}
        currency={currency}
        appId={appIdInFilter}
      />
      <ModuleBilling
        fetchLogs={fetchLogs}
        isLoading={isLoading}
        currency={currency}
        appId={appIdInFilter}
      />
      <div className="platform-usage-container">
        <PlatformBilling
          fetchLogs={fetchLogs}
          isLoading={isLoading}
          currency={currency}
          appId={appIdInFilter}
          billingMonth={getMonthYearString(selectedDate)}
          selectedDate={selectedDate}
        />
      </div>
    </div>
  );
}

export default PermissionWrapper(Usage, useGetUserPermissions, getPermission('usagePage'));
