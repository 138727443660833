/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { RECORD_PAGE_TABS } from '../constants/record';

const initialState = {
  currentTab: RECORD_PAGE_TABS.OVERVIEW,
  currentCredential: {},
  reviewTags: {},
  hitsReviewTags: {},
  transactionData: {},
  flagData: {},
  v2FlagData: {},
  moduleData: {},
  rawRecordData: [],
  recordData: {},
  hitData: {},
  availableCardData: {},
  statusHistory: [],
  failureReason: '',
  reviewReasonsConfig: {},
  contributingModulesForEndState: {},
  reasonBehindTheStatusModules: [],
  selectedModuleToScroll: '',
  isApplicationDetailsCollapsibleOpened: false,
  isReasonBehindStatusCollapsibleOpened: true,
};

export const dataSlice = createSlice({
  name: 'appDetails',
  initialState,
  reducers: {
    updateCurrentCredential: (state, action) => {
      state.currentCredential = action.payload;
    },
    resetCurrentCredential: (state) => {
      state.currentCredential = initialState.currentCredential;
    },
    resetData: () => initialState,
    initReviewTags: (state, action) => {
      state.reviewTags = action.payload || initialState.reviewTags;
    },
    updateReviewTagGroupSelectedValue: (state, action) => {
      const {
        moduleId, requestId, tagGroupKey, selectedValue,
      } = action.payload;
      if (!moduleId || !requestId || !tagGroupKey) return;
      const updatedTagGroups = state.reviewTags[moduleId][requestId].tagGroups.map((tagGroup) => {
        if (tagGroup.key === tagGroupKey) {
          return {
            ...tagGroup,
            selectedValue,
          };
        }
        return tagGroup;
      });
      state.reviewTags[moduleId][requestId].tagGroups = updatedTagGroups;
    },

    initHitsReviewTags: (state, action) => {
      state.hitsReviewTags = action.payload || initialState.hitsReviewTags;
    },

    updateHitsReviewTagGroupSelectedValue: (state, action) => {
      const {
        moduleId, requestId, hitId, tagGroupKey, selectedValue,
      } = action.payload;
      if (!moduleId || !requestId || !hitId || !tagGroupKey) return;
      const updatedTagGroups = state
        .hitsReviewTags[moduleId][requestId].tagGroups.map((tagGroup) => {
          if (tagGroup.key === tagGroupKey) {
            return {
              ...tagGroup,
              selectedValue: {
                ...tagGroup.selectedValue,
                [hitId]: selectedValue,
              },
            };
          }
          return tagGroup;
        });
      state.hitsReviewTags[moduleId][requestId].tagGroups = updatedTagGroups;
    },

    initTransactionData: (state, action) => {
      state.transactionData = action.payload || initialState.transactionData;
    },
    updateTransactionData: (state, action) => {
      state.transactionData = {
        ...(state.transactionData || initialState.transactionData),
        ...(action.payload || {}),
      };
    },
    initFlagData: (state, action) => {
      state.flagData = action.payload || initialState.flagData;
    },
    initV2FlagData: (state, action) => {
      state.v2FlagData = action.payload || initialState.v2FlagData;
    },
    initModuleData: (state, action) => {
      state.moduleData = action.payload || initialState.moduleData;
    },
    initRawRecordData: (state, action) => {
      state.rawRecordData = action.payload || initialState.rawRecordData;
    },
    initRecordData: (state, action) => {
      state.recordData = action.payload || initialState.recordData;
    },
    initHitData: (state, action) => {
      state.hitData = action.payload || initialState.hitData;
    },
    updateStatusHistory: (state, action) => {
      set(state, 'statusHistory', action.payload || []);
    },
    updateFailureReason: (state, action) => {
      set(state, 'failureReason', action.payload || initialState.failureReason);
    },
    updateContributingModulesDetails: (state, action) => {
      set(state, 'contributingModulesForEndState', action.payload);
    },
    updateAvailableData: (state, action) => {
      const { moduleId, data } = action.payload;
      if (!moduleId) return;
      state.availableCardData = {
        ...state.availableCardData,
        [moduleId]: {
          ...state.availableCardData[moduleId] || {},
          ...data,
        },
      };
    },
    updateReviewReasonsConfig: (state, action) => {
      state.reviewReasonsConfig = action.payload;
    },
    updateReasonBehindTheStatusModules: (state, action) => {
      state.reasonBehindTheStatusModules = action.payload;
    },
    updateCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    updateSelectedModuleToScroll: (state, action) => {
      state.selectedModuleToScroll = action.payload;
    },
    updateIsApplicationDetailsCollapsibleOpened: (state, action) => {
      state.isApplicationDetailsCollapsibleOpened = action.payload;
    },
    updateIsReasonBehindStatusCollapsibleOpened: (state, action) => {
      state.isReasonBehindStatusCollapsibleOpened = action.payload;
    },
    resetStateOnPopulateRecord: (state) => {
      state.currentTab = initialState.currentTab;
      state.reviewReasonsConfig = initialState.reviewReasonsConfig;
      state.reasonBehindTheStatusModules = initialState.reasonBehindTheStatusModules;
      state.contributingModulesForEndState = initialState.contributingModulesForEndState;
      state.selectedModuleToScroll = initialState.selectedModuleToScroll;
      state.isApplicationDetailsCollapsibleOpened = initialState
        .isApplicationDetailsCollapsibleOpened;
      state.isReasonBehindStatusCollapsibleOpened = initialState
        .isReasonBehindStatusCollapsibleOpened;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateCurrentCredential, resetCurrentCredential,
  initReviewTags, updateReviewTagGroupSelectedValue,
  initHitsReviewTags, updateHitsReviewTagGroupSelectedValue,
  resetData,
  initTransactionData,
  updateTransactionData,
  initFlagData,
  initV2FlagData,
  initModuleData,
  initRawRecordData,
  initRecordData,
  initHitData,
  updateAvailableData,
  updateStatusHistory,
  updateFailureReason,
  updateReviewReasonsConfig,
  updateContributingModulesDetails,
  updateReasonBehindTheStatusModules,
  updateCurrentTab,
  updateSelectedModuleToScroll,
  updateIsApplicationDetailsCollapsibleOpened,
  updateIsReasonBehindStatusCollapsibleOpened,
  resetStateOnPopulateRecord,
} = dataSlice.actions;
export default dataSlice.reducer;
