import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ModulesUsed from './sections/ModulesUsed';
import ReasonBehindStatus from './sections/ReasonBehindStatus';

function LeftPanelBody({ selectedModule, onModuleClick }) {
  const moduleData = useSelector((state) => state.appDetails.moduleData);

  return (
    <div id="leftPanel__body">
      <ModulesUsed
        moduleData={moduleData}
        onModuleClick={onModuleClick}
        selectedModule={selectedModule}
      />
      <ReasonBehindStatus
        moduleData={moduleData}
        onModuleClick={onModuleClick}
        selectedModule={selectedModule}
      />
    </div>
  );
}

LeftPanelBody.propTypes = {
  selectedModule: PropTypes.string.isRequired,
  onModuleClick: PropTypes.func.isRequired,
};

export default LeftPanelBody;
