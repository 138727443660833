const REVIEW_DECISION_REASON_TYPES = {
  SINGLE: 'single',
  CUSTOM: 'custom',
  MULTIPLE: 'multiple',
};

const RECORD_PAGE_TABS = {
  OVERVIEW: 'overview',
  TIMELINE: 'timeline',
  ALL_MODULES: 'allModules',
};

const RECORD_PAGE_TAB_OPTIONS = [
  {
    label: 'Overview',
    value: RECORD_PAGE_TABS.OVERVIEW,
  },
  {
    label: 'Timeline',
    value: RECORD_PAGE_TABS.TIMELINE,
  },
  {
    label: 'All Modules',
    value: RECORD_PAGE_TABS.ALL_MODULES,
  },
];

const END_STATUSES = {
  USER_CANCELLED: 'user_cancelled',
  AUTO_APPROVED: 'auto_approved',
  AUTO_DECLINED: 'auto_declined',
  MANUALLY_APPROVED: 'manually_approved',
  MANUALLY_DECLINED: 'manually_declined',
  ERROR: 'error',
  NEEDS_REVIEW: 'needs_review',
  EXPIRED: 'expired',
};

export { RECORD_PAGE_TABS, RECORD_PAGE_TAB_OPTIONS, END_STATUSES };
export default REVIEW_DECISION_REASON_TYPES;
