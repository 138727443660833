/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import LeftArrowIcon from '../../../assests/icons/leftArrow08.svg';
import { constants } from '../../../config';
import ShareApplications from './ShareApplications';
import HeaderNavigation from './HeaderNavigation';
import { updateCurrentTab } from '../../../reducers/appDetails';
import { RECORD_PAGE_TAB_OPTIONS, RECORD_PAGE_TABS, END_STATUSES } from '../../../constants/record';

import './Header.scss';

function RecordHeader({
  loading,
  pageConfig,
  recordTitle,
  loadPrevRecord,
  loadNextRecord,
  currentPage,
  onCopyApplicationLink,
  searchId,
}) {
  const dispatch = useDispatch();
  const totalRows = useSelector((state) => state.data.totalElements);
  const contributingModulesForEndState = useSelector(
    (state) => state.appDetails.contributingModulesForEndState,
  );
  const contributingSuperModuleIdsForEndState = useSelector(
    (state) => get(state, 'appDetails.contributingModulesForEndState.contributingSuperModuleIdsForEndState', []),
  );
  const contributingModuleIdsForEndState = useSelector(
    (state) => get(state, 'appDetails.contributingModulesForEndState.contributingModuleIdsForEndState', []),
  );
  const transactionData = useSelector((state) => state.appDetails.transactionData);
  const { status } = transactionData;
  const [tabOptions, setTabOptions] = useState(RECORD_PAGE_TAB_OPTIONS);

  useEffect(() => {
    const hasContributingModules = !(
      isEmpty(
        contributingModulesForEndState.contributingModuleIdsForEndState,
      )
      && isEmpty(
        contributingModulesForEndState.contributingSuperModuleIdsForEndState,
      )
    );

    if (!hasContributingModules) {
      switch (status) {
        case END_STATUSES.AUTO_APPROVED:
          setTabOptions(RECORD_PAGE_TAB_OPTIONS);
          dispatch(updateCurrentTab(RECORD_PAGE_TABS.OVERVIEW));
          break;
        default:
          setTabOptions(
            tabOptions.filter((option) => option.value !== RECORD_PAGE_TABS.OVERVIEW),
          );
          dispatch(updateCurrentTab(RECORD_PAGE_TABS.ALL_MODULES));
          break;
      }
    } else {
      setTabOptions(RECORD_PAGE_TAB_OPTIONS);
      dispatch(updateCurrentTab(RECORD_PAGE_TABS.OVERVIEW));
    }
  }, [contributingModulesForEndState,
    contributingSuperModuleIdsForEndState,
    contributingModuleIdsForEndState,
    status,
  ]);

  const stateToApplication = { page: currentPage };
  if (searchId) {
    stateToApplication.searchId = searchId;
  }

  const getCurrentApplicationIndex = () => {
    const { currentElement } = pageConfig.pagination;
    return (currentElement + 1) + ((currentPage) * constants.maxRecordsInTable);
  };

  if (loading) {
    return '';
  }

  return (
    <div id="record__header">
      <div id="header__left">
        <Link
          id="header__back"
          to={pageConfig.redirectPage}
          state={stateToApplication}
        >
          <img src={LeftArrowIcon} alt="" />
        </Link>
        <p data-hj-suppress id="header__title">
          {recordTitle}
        </p>
      </div>
      <div id="record__center">
        <HeaderNavigation tabOptions={tabOptions} />
      </div>
      <div id="header__right">
        <ShareApplications onCopyApplicationLink={onCopyApplicationLink} />
        <div id="header__right_nav">
          <button
            type="button"
            id="header__right_nav_prev_button"
            onClick={loadPrevRecord}
          >
            <img src={LeftArrowIcon} alt="" />
          </button>
          <span>{`${getCurrentApplicationIndex()} of ${totalRows} applications`}</span>
          <button
            type="button"
            id="header__right_nav_next_button"
            onClick={loadNextRecord}
          >
            <img src={LeftArrowIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

RecordHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageConfig: PropTypes.object.isRequired,
  recordTitle: PropTypes.string.isRequired,
  loadPrevRecord: PropTypes.func.isRequired,
  loadNextRecord: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onCopyApplicationLink: PropTypes.func.isRequired,
  searchId: PropTypes.string.isRequired,
};

export default RecordHeader;
