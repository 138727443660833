import React from 'react';
import PropTypes from 'prop-types';

function MetaData({
  data,
}) {
  if (data.length > 0) {
    return (
      <div id="summary__metadata__container">

        <div id="summary__metadata__details">
          {data && data.map(({
            id, label, value, componentRenderer,
          }) => (
            <p id={id} key={id}>
              {label}
              :
              <span>
                {componentRenderer ? componentRenderer({ value }) : value}
              </span>
            </p>
          ))}
        </div>
      </div>
    );
  }
  return '';
}

MetaData.defaultProps = {
  data: [],
};

MetaData.propTypes = {
  data: PropTypes.array,
};

export default MetaData;
