import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { RECORD_PAGE_TABS } from '../../../../constants/record';
import LeftPanelItem from '../LeftPanelItem';

function ReasonBehindStatus({ moduleData, onModuleClick, selectedModule }) {
  const [modulesToRender, setModulesToRender] = useState({});
  const currentTab = useSelector((state) => state.appDetails.currentTab);
  const reasonBehindTheStatusModules = useSelector(
    (state) => state.appDetails.reasonBehindTheStatusModules,
  );

  useEffect(() => {
    if (!isEmpty(moduleData) && !isEmpty(reasonBehindTheStatusModules)) {
      const modulesToRenderInit = Object.fromEntries(
        Object.entries(moduleData)
          .filter(([moduleId]) => reasonBehindTheStatusModules.includes(moduleId)),
      );
      setModulesToRender(modulesToRenderInit);
    }
  }, [moduleData, reasonBehindTheStatusModules]);

  if (currentTab !== RECORD_PAGE_TABS.OVERVIEW) {
    return null;
  }

  return (
    <>
      {currentTab === RECORD_PAGE_TABS.OVERVIEW && (
      <>
        <h2>Reason Behind the Status</h2>
        <div id="leftPanel__body__modules">
          {!isEmpty(modulesToRender) && !isEmpty(reasonBehindTheStatusModules) ? (
            Object.entries(modulesToRender).map(
              ([moduleId, { moduleName, isSuccess, subType }]) => (
                <LeftPanelItem
                  moduleName={moduleName}
                  moduleId={moduleId}
                  subType={subType}
                  isSuccess={isSuccess}
                  onModuleClick={onModuleClick}
                  selectedModule={selectedModule}
                  key={moduleId}
                />
              ),
            )
          ) : (
            <span id="leftPanel__body__no_module">No Issues faced by the user</span>
          )}
        </div>
      </>
      )}
    </>
  );
}

ReasonBehindStatus.propTypes = {
  moduleData: PropTypes.object.isRequired,
  onModuleClick: PropTypes.func.isRequired,
  selectedModule: PropTypes.string.isRequired,
};

export default ReasonBehindStatus;
