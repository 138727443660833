import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FaceMatchPanel from '../Panels/FaceMatchPanel';
import '../../Details.scss';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';
import { recordsToRender } from './utils';

function FaceMatch({
  moduleName, subType, records, hideHeader, hideTabs, hideCreatedAtAndStatus,
}) {
  const [dbCheckDetailArray, setDbCheckDetailArray] = useState([]);
  const [value, setValue] = useState(0);
  const currentTabToView = useSelector((state) => state.appDetails.currentTab) || '';

  useEffect(() => {
    const filteredRecords = recordsToRender(records, currentTabToView);
    setDbCheckDetailArray(filteredRecords);
    setValue(0);
  }, [records, currentTabToView]);

  return (
    <div id="details__container">
      {!hideHeader && <CommonHeader moduleName={moduleName} subType={subType} />}
      {!hideTabs && (
      <CustomTabs
        id="details__body_tabs"
        tabIndex={value}
        setTabIndex={setValue}
        tabArray={dbCheckDetailArray}
      />
      )}
      {dbCheckDetailArray.map((item, index) => (
        <FaceMatchPanel
          key={item.id}
          value={value}
          index={index}
          item={item}
          createdAt={item.createdAt}
          status={item.apiStatus}
          hideCreatedAtAndStatus={hideCreatedAtAndStatus}
        />
      ))}
    </div>
  );
}

FaceMatch.defaultProps = {
  hideHeader: false,
  hideTabs: false,
  hideCreatedAtAndStatus: false,
};

FaceMatch.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  hideHeader: PropTypes.bool,
  hideTabs: PropTypes.bool,
  hideCreatedAtAndStatus: PropTypes.bool,
};
export default FaceMatch;
