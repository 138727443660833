/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import { get, isEmpty } from 'lodash';
import AutoApproved from './AutoApproved';
import EmptyState from './Empty';
import { END_STATUSES } from '../../../../../constants/record';

function ReasonBehindTheStatusEmptyState() {
  const transactionData = useSelector((state) => get(state, 'appDetails.transactionData', {}));
  const status = get(transactionData, 'status', '');

  if (isEmpty(status)) {
    return null;
  }

  if (status === END_STATUSES.MANUALLY_APPROVED) {
    return <AutoApproved />;
  }

  if (status === END_STATUSES.AUTO_APPROVED) {
    return <AutoApproved />;
  }

  return <EmptyState />;
}

export default ReasonBehindTheStatusEmptyState;
